import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ChildrenOutletContexts,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { SidenavComponent } from './sidenav/sidenav.component';
import { HeaderComponent } from './header/header.component';
import { MenuGroup } from '../shared/model/menu-group.model';
import { MenuVariants } from '../shared/helpers/menu-variants.helper';
import { filter } from 'rxjs';
import { DefaultService } from 'generated-src';
import { HistoryComponent } from '../shared/components/history/history.component';
// import { ThemeDirective } from '../shared/helpers/theme.directive';

@Component({
  selector: 'inf-layout',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    RouterOutlet,
    SidenavComponent,
    HistoryComponent,
  ],
  providers: [DefaultService],
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit {
  isSidenavEnabled: boolean = true;
  sidenav: MenuGroup[] = MenuVariants.MAIN_MENU;

  constructor(
    private contexts: ChildrenOutletContexts,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setMenuType();
    this.observeNavigationEnd();
  }

  observeNavigationEnd(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setMenuType();
      });
  }

  setMenuType(): void {
    this.isSidenavEnabled = true;
    const menuType = this.contexts.getContext('primary')?.route?.snapshot.data[
      'menuType'
    ] as string;
    switch (menuType) {
      case 'ADMIN_MENU':
        this.sidenav = MenuVariants.ADMIN_MENU;
        break;
      case 'NONE':
        this.isSidenavEnabled = false;
        break;
      default:
        this.sidenav = MenuVariants.MAIN_MENU;
        break;
    }
  }
}
