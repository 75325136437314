import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TagComponent } from '../shared/components/tag/tag.component';
import { ButtonModule } from 'primeng/button';
import { OrdersStatusComponent } from './orders-status/orders-status.component';
import { EmptyStateComponent } from '../shared/components/empty-state/empty-state.component';
import { SearchSystemComponent } from '../shared/components/search-system/search-system.component';
import { SearchFilter } from '../shared/model/search-filter.model';
import { AlertService } from '@harmanpa/ng-cae';
import { RouterModule } from '@angular/router';
import { DefaultService, DocumentSummary } from 'generated-src';
import { TooltipModule } from 'primeng/tooltip';
import { HttpClient } from '@angular/common/http';
import * as L from 'leaflet';
import { Chart } from 'chart.js';
@Component({
  selector: 'inf-orders',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    DividerModule,
    EmptyStateComponent,
    FormsModule,
    InputTextModule,
    OrdersStatusComponent,
    SearchSystemComponent,
    TableModule,
    TagComponent,
    TooltipModule,
    RouterModule,
  ],
  templateUrl: './orders.component.html',
})
export class OrdersComponent implements OnInit {
  @Input() showDetails: boolean = true;
  @Input() rowsNumber: number = 10;
  orders: DocumentSummary[] = [];
  ordersSyncing: boolean = false;
  includeSearch: boolean = true;
  selectOrders: boolean = false;
  loading: boolean = true;
  browserData: any = {};

  constructor(
    private api: DefaultService,
    private alertService: AlertService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.api.findOrders().subscribe({
      next: orders => {
        this.orders = orders;
        this.loading = false;
      },
      error: err => {
        this.alertService.error(err);
        this.loading = false;
      },
    });
  }

  public syncOrders(): void {
    this.http.get('/api/external/orders').subscribe({
      next: orders => console.log(orders),
      error: err => this.alertService.error(err),
    });
  }
}
