import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { NotificationPanelComponent } from './notification-panel/notification-panel.component';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { TooltipModule } from 'primeng/tooltip';
import {
  ActivityService,
  CalculationDescription,
  CalculationSummary,
  InProgressService,
  IntegrationExecution,
  IntegrationService,
  StatusCompare,
  automaticUnsubscribe,
  camelize,
  StatusEnum,
} from '@harmanpa/ng-cae';
import { Router } from '@angular/router';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'inf-notification-button',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    NotificationPanelComponent,
    OverlayPanelModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    TooltipModule,
  ],
  templateUrl: './notification-button.component.html',
})
export class NotificationButtonComponent {
  @Input() autoOpenNotifications: boolean;
  @Output() notificationsPanelChange = new EventEmitter<Event>();
  display: boolean = false;
  tasks: DisplayTask[] = [];
  integrations: DisplayTask[] = [];
  numberOfTasks = 0;
  numberOfIntegrations = 0;
  numberTotal = 0;
  numberComplete = 0;
  numberActive = 0;
  previousNumberTotal = 0;
  numberByStatus: { [key: string]: number } = {};
  tooltip = '';
  connected = false;
  @ViewChild('notificationsPanel') notificationsPanel: OverlayPanel;
  @ViewChild('notificationsButton') notificationsButton: ElementRef;

  constructor(
    private inProgress: InProgressService,
    private integrationService: IntegrationService,
    private router: Router,
    private activityService: ActivityService
  ) {}

  ngOnInit(): void {
    this.activityService
      .observeConnected()
      .subscribe(connected => (this.connected = connected));
    this.inProgress
      .observe()
      .pipe(automaticUnsubscribe(this))
      .subscribe(response => {
        this.tasks = response.map(task => this.displayTask(task));
        this.countTasks();
        this.toggleNotificationsPanel();
      });
    this.integrationService
      .watchIntegrations()
      .pipe(automaticUnsubscribe(this))
      .subscribe(response => {
        this.integrations = response.map(integration =>
          this.displayIntegration(integration)
        );
        this.countTasks();
        this.toggleNotificationsPanel();
      });
  }

  countTasks(): void {
    this.tasks.sort((a, b) => {
      if (b.updated && a.updated) {
        return a.updated > b.updated ? -1 : 1;
      }
      return 0;
    });

    this.integrations.sort((a, b) => {
      if (a.updated && b.updated) {
        return a.updated > b.updated ? -1 : 1;
      }
      return 0;
    });
    this.numberOfTasks = this.tasks.length;
    this.numberOfIntegrations = this.integrations.length;
    this.numberTotal = this.numberOfTasks + this.numberOfIntegrations;
    this.numberByStatus = {};
    for (const item of this.tasks) {
      this.numberByStatus[item.status as string] =
        this.numberByStatus[item.status as string] > 0
          ? this.numberByStatus[item.status as string] + 1
          : 1;
    }

    for (const item of this.integrations) {
      this.numberByStatus[item.status as string] =
        this.numberByStatus[item.status as string] > 0
          ? this.numberByStatus[item.status as string] + 1
          : 1;
    }

    // Sets number of completed tasks by adding tasks where all with the same documentid are completed or failed.
    this.numberComplete = Object.values(
      this.tasks.reduce((groupedTasks, task) => {
        (groupedTasks[task.documentId] ||= []).push(task);
        return groupedTasks;
      }, {} as { [key: string]: DisplayTask[] })
    ).filter(tasks =>
      tasks.every(
        task => task.status === 'Failed' || task.status === 'Complete'
      )
    ).length;

    this.numberActive =
      this.numberTotal -
      ((this.numberByStatus['Failed'] || 0) +
        (this.numberByStatus['Complete'] || 0));
    this.tooltip = Object.keys(this.numberByStatus)
      .sort(StatusCompare)
      .map(
        status => '' + this.numberByStatus[status] + ' ' + status.toLowerCase()
      )
      .join(', ');
  }

  toggleNotificationsPanel(): void {
    if (
      this.notificationsPanel &&
      this.notificationsButton &&
      this.autoOpenNotifications
    ) {
      if (this.previousNumberTotal === 0 && this.numberTotal > 0) {
        this.notificationsPanel.show(
          new Event('trigger'),
          this.notificationsButton.nativeElement
        );
      } else if (this.previousNumberTotal > 0 && this.numberTotal === 0) {
        this.notificationsPanel.hide();
      }
    }
    this.previousNumberTotal = this.numberTotal;
  }

  openTasks(task: string): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/tasks/${task}`])
    );
    window.open(url, '_blank');
  }

  notificationToggle(event: Event): void {
    this.notificationsPanel.toggle(
      event,
      this.notificationsButton.nativeElement
    );
    this.notificationsPanelChange.emit(event);
  }

  displayIntegration(integration: IntegrationExecution): DisplayTask {
    return {
      taskType: 'integration',
      id: integration.id as string,
      type: integration.integration as string,
      status: integration.status as StatusEnum,
      documentId: integration.id as string,
      title: integration.integration as string,
      description: 'Execution of ' + integration.flow,
      progress: integration.progress,
      started: integration.created,
      updated: integration.updated,
    };
  }

  displayTask(task: [CalculationSummary, CalculationDescription]): DisplayTask {
    return {
      taskType: 'task',
      id: task[0].id as string,
      type: task[0].documentType as string,
      title: task[1].title,
      status: task[0].status as StatusEnum,
      documentId: task[0].documentId as string,
      description: task[1].message,
      started: task[0].created,
      progress: task[0].progress,
      updated: task[0].updated,
      isRead: task[0].isRead,
      moduleName: task[1].document,
    };
  }

  clearCompleted(): void {
    setTimeout(() => {
      this.inProgress.clearDocumentIdComplete();
      this.integrationService.clearComplete();
    }, 0);
  }

  hidePanel(): void {
    //Set all tasks and integrations as read
    this.tasks.forEach(task => {
      task.isRead = true;
    });
    this.inProgress.setTasksAsRead(this.tasks.map(task => task.documentId));

    this.integrations.forEach(integration => {
      integration.isRead = true;
    });
    this.integrationService.setIntegrationAsRead(
      this.integrations.map(integration => integration.documentId)
    );
  }
}

export interface DisplayTaskGroup {
  type: string;
  title: string;
  status: StatusEnum;
  progress?: number;
  updated?: Date;
  started?: Date;
  children: DisplayTask[];
  isRead?: boolean;
  moduleName?: string;
}

export interface DisplayTask {
  taskType: string;
  id: string;
  type: string;
  status: StatusEnum;
  documentId: string;
  title: string;
  description: string;
  started?: Date;
  progress?: number;
  updated?: Date;
  isRead?: boolean;
  moduleName?: string;
}
