<div class="grid grid-nogutter surface-primary-dark md:px-4 lg:px-6 py-8">
  <div class="col-12 md:col-12 flex-column md:px-0 lg:px-0 px-4 mb-2">
    <p class="text-white heading-xlarge text-semibold mb-3">
      You're in good company
    </p>
    <p class="text-white body-medium text-regular mb-6">
      Leading manufacturers across industries use Infinitive.
    </p>
  </div>
  <!-- <div class="col-12 md:col-12 flex align-items-center justify-content-between"> -->
  <div
    class="col-12 md:col-12 px-4 align-items-center lg:overflow-x-hidden overflow-x-scroll no-scrollbar flex flex-nowrap lg:justify-content-between md:justify-content-between">
    <div class="p-3">
      <img
        class="img-object-contain"
        id="rak"
        ngSrc="assets/images/misc/companies/rak-white.png"
        alt="Image"
        height="50"
        width="147" />
    </div>
    <div class="p-3">
      <img
        class="img-object-contain"
        id="fjs"
        ngSrc="assets/images/misc/companies/FutureJoinerySystems-white.png"
        alt="Image"
        height="60"
        width="58" />
    </div>
    <div class="p-3">
      <img
        id="evenKeel"
        class="img-object-contain"
        ngSrc="assets/images/logos/ekpi-grey.png"
        alt="Image"
        height="80"
        width="135" />
    </div>
    <div class="p-3">
      <img
        id="emptyState"
        class="img-object-contain"
        src="assets/images/misc/companies/emptystate.png"
        alt="Image"
        height="40"
        width="275" />
    </div>
    <div class="p-3">
      <img
        id="WarrenWeb"
        class="img-object-contain"
        src="assets/images/logos/warren.png"
        alt="Image"
        height="60"
        width="177" />
    </div>
  </div>
</div>
