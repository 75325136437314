<div class="mt-3">
  <ul class="list-none p-0 m-0">
    <li
      class="flex flex-column align-items-center md:flex-row md:align-items-center md:justify-content-between mb-3">
      <div class="flex w-full align-items-center">
        <div>
          <span
            class="block body-medium"
            >{{configurator.data?.["name"] }}</span
          >
          <span
            class="block body-small-semibold"
            *ngIf="publishedConfigurator"
            (click)="open(publishedConfigurator)"
            style="cursor: pointer"
            >{{ publishedConfigurator.document?.infinitiveDomain }}</span
          >
          <div
            *ngIf="!publishedConfigurator"
            class="text-600 body-xtra-small">
            Edited {{ configurator.lastEdited | since }}
          </div>
          <div
            *ngIf="publishedConfigurator"
            class="text-600 body-xtra-small">
            Published {{ publishedConfigurator.versionDate | since }}
          </div>
        </div>
        <div class="flex-row ml-auto">
          <!-- EDIT -->
          <p-button
            pTooltip="Edit Configurator"
            tooltipPosition="left"
            icon="pi pi-pencil"
            styleClass="p-button-text p-button-rounded mr-2"
            (click)="edit(configurator)"></p-button>
        </div>
      </div>
    </li>
  </ul>
</div>
