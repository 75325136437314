import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CalculationDescription,
  InProgressService,
  TaskService,
  automaticUnsubscribe,
} from '@harmanpa/ng-cae';
import { CalculationSummary } from 'generated-src';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'inf-progress',
  standalone: true,
  templateUrl: './progress.component.html',
  imports: [CommonModule, TagModule],
})
export class ProgressComponent {
  progress: number = 0;
  n = 0;
  @Input() objectID: string;
  @Input() type: string;
  @Output() progressChanged: EventEmitter<boolean> = new EventEmitter();

  constructor(private inProgressService: InProgressService) {}

  ngOnInit() {
    console.log('progress type and id', this.objectID);
    this.checkprogress(this.type, this.objectID);
  }

  checkprogress(type: string, id: string): void {
    this.inProgressService
      .observe(type, id)
      .pipe(automaticUnsubscribe(this))
      .subscribe(
        (
          calculationSummary: [CalculationSummary, CalculationDescription][]
        ) => {
          // console.log('calculationSummary',calculationSummary);
          const calcprogs = calculationSummary.map(
            cal => cal[0].progress as number
          );
          // Calculate the sum of all numbers in the array
          const sum = calcprogs.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );
          // Calculate the average by dividing the sum by the length of the array
          this.progress = sum / calcprogs.length;
          this.n = calcprogs.length;
          if (this.n > 0) {
            this.progressChanged.emit(true);
          } else {
            this.progressChanged.emit(false);
          }
          // console.log(this.n, this.progress);
        }
      );
  }
}
