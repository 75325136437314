<div
  class="theme-preset px-4 py-2 border-round-2xl cursor-pointer"
  [class.selected-preset]="isSelected">
  <p class="body-small pb-1">{{ themeName }}</p>
  <div class="flex align-items-center">
    <div
      class="w-2rem h-2rem border-circle mr-1"
      [style.background-color]="primaryColor"></div>
    <div
      class="small-color border-circle mr-1"
      [style.background-color]="secondaryColor"></div>
    <div
      class="small-color border-circle"
      [style.background-color]="tertiaryColor"></div>
  </div>
</div>
