import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'inf-customer',
  templateUrl: './customer.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class CustomerComponent implements OnInit {
  loading = false;
  customer: any;

  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit(): void {
    this.loading = true;
    const customerId = this.route.snapshot.params['customer_id'];
    this.http.get<any>(`/api/customers/${customerId}`).subscribe(res => {
      this.customer = res;
      console.log(res); // Check if 'orders' data is present in the response

      this.loading = false;
    });
  }
}
