<ng-container *ngIf="isModalOpen">
  <div class="modal-overlay"></div>
  <div
    class="card py-6 px-5 modal push"
    [ngStyle]="{ 'max-width': '460px' }">
    <h3 class="mb-5 font-bold">Welcome to Infinitive 🎉!</h3>
    <div
      class="flex flex-column mb-2"
      *ngIf="!inProgress"
      [formGroup]="wsControl">
      <div class="flex gap-8">
        <div class="flex-1">
          <div class="field">
            <label class="block">Give a name to your new workspace:</label>
            <input
              pInputText
              formControlName="name"
              placeholder="Workspace Name"
              type="text" />
            <inf-error [control]="wsControl.controls['name']"></inf-error>
          </div>
          <div class="field">
            <label class="block">Why not invite team members?</label>
            <p-chips
              formControlName="emails"
              separator=","
              placeholder="Hint: a, b, c"></p-chips>
            <inf-error [control]="wsControl.controls['emails']"></inf-error>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-3">
      <button
        class="p-button-outlined p-button-sm w-full"
        (click)="cancel()"
        label="Later"
        pButton></button>
      <button
        class="p-button-sm w-full"
        (click)="confirm()"
        label="Done"
        pButton></button>
    </div>
  </div>
</ng-container>
