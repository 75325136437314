import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  AlertService,
  JavaClassPipe,
  SincePipe,
  TaskService,
  automaticUnsubscribe,
} from '@harmanpa/ng-cae';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, map, timer, of } from 'rxjs';
import { IntegrationService } from '@harmanpa/ng-cae';
import {
  DisplayTaskGroup,
  DisplayTask,
} from '../../notification-button.component';
import { TruncatePipe } from 'src/app/shared/pipes/truncate.pipe';

@Component({
  selector: 'inf-notification-content',
  templateUrl: './notification-content.component.html',
  imports: [
    ButtonModule,
    ChipModule,
    CommonModule,
    DividerModule,
    JavaClassPipe,
    SincePipe,
    TooltipModule,
    TruncatePipe,
  ],
  standalone: true,
})
export class NotificationContentComponent implements OnInit {
  @Input() task: DisplayTaskGroup;
  @Input() last = false;
  started: Observable<Date | undefined>;
  integrations: DisplayTask[] = [];
  isDetailsopened = false;
  age: number;

  constructor(
    private taskService: TaskService,
    private alertService: AlertService,
    private integrationService: IntegrationService
  ) {}

  ngOnInit(): void {
    this.age = this.task.started
      ? Date.now() - (this.task.started as Date).getTime()
      : 0;
    // Trigger the "since" pipe every 5s
    this.started = this.task.started
      ? timer(0, 5000).pipe(
          automaticUnsubscribe(this),
          map(_ => new Date(this.task.started?.getTime() as number))
        )
      : of(undefined);
  }

  cancel(task: DisplayTask): void {
    (task.taskType === 'task'
      ? this.taskService.cancelTask(task).pipe(map(obj => obj.success))
      : this.integrationService.cancelIntegration(task.id)
    ).subscribe(result => {
      if (result) {
        this.alertService.success(
          'Requested cancelling of task',
          'Cancelling task'
        );
      } else {
        this.alertService.warning('Could not cancel task', 'Cancelling task');
      }
    });
  }

  retry(task: DisplayTask): void {
    this.taskService.retryTask(task).subscribe(result => {
      if (result) {
        this.alertService.success(
          'Requested resubmission of task',
          'Resubmitting task'
        );
      } else {
        this.alertService.warning(
          'Could not resubmit task',
          'Resubmitting task'
        );
      }
    });
  }

  report(task: DisplayTask): void {
    this.taskService.reportTask(task).subscribe(result => {});
  }

  toggleDetails(): void {
    this.isDetailsopened = !this.isDetailsopened;
  }
}
