<div class="surface-section px-4 pb-4 pt-3 md:px-6 lg:px-8">
  <!-- <div class="border-top-1 border-300 pt-5 text-center"> -->
  <div class="pt-5 text-center">
    <img
      ngSrc="assets/images/logos/infinitive.png"
      alt="Image"
      height="36"
      width="40"
      class="img-object-contain" />
    <!-- <div class="p-0 mx-0 my-5 flex flex-column justify-content-center align-items-center lg:flex-row">
      <div class="mx-2">
        <a (click)="onClickScroll('team')" class="text-gray-500 cursor-pointer body-small line-height-3">About Us</a>
      </div>
      <div class="mx-2">
        <a (click)="onClickScroll('feature')" class="text-gray-500 cursor-pointer body-small line-height-3">Product</a>
      </div> -->
    <!-- <li><a class="text-600 cursor-pointer line-height-3 lg:mr-5">Investor Relations</a></li> -->
    <!-- <div class="mx-2">
        <a href="https://cae.tech/careers" class="text-gray-500 cursor-pointer body-small line-height-3 ">Careers</a>
      </div> -->
    <!-- <li><a class="text-600 cursor-pointer line-height-3">Media Kit</a></li> -->
    <!-- </div> -->
    <div class="flex align-items-center justify-content-center my-5">
      <!-- <a
        class="cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block inline-flex justify-content-center align-items-center mr-5"
        style="width: 2.5rem; height: 2.5rem">
        <i class="pi pi-twitter"></i>
      </a> -->
      <a
        href="https://www.linkedin.com/company/cae-tech-limited"
        target="_new"
        class="cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block inline-flex justify-content-center align-items-center"
        style="width: 2.5rem; height: 2.5rem">
        <i class="pi pi-linkedin justify-content-center"></i>
      </a>
      <!-- <a
        class="cursor-pointer border-circle bg-bluegray-100 text-bluegray-500 block inline-flex justify-content-center align-items-center"
        style="width: 2.5rem; height: 2.5rem">
        <i class="pi pi-github"></i>
      </a> -->
    </div>
    <div class="text-center">
      <p class="body-xtra-small text-gray-500 mb-2">
        Infinitive is a trading name of CAE Tech Limited
      </p>
      <p class="body-xtra-small text-gray-500 mb-2">
        Registered in England and Wales, company number: 10361666
      </p>
      <p class="body-xtra-small text-gray-500">
        Registered office: Nelson House, 2 Hamilton Terrace, Leamington Spa,
        CV32 4LY, UK
      </p>
    </div>
    <div class="text-center mt-6">
      <a
        class="mr-5 body-xtra-small text-gray-500 cursor-pointer"
        [routerLink]="['/legal', 'privacy']"
        >Privacy Policy</a
      >
      <a
        class="body-xtra-small text-gray-500 cursor-pointer"
        [routerLink]="['/legal', 'privacy']"
        >Terms of Service</a
      >
    </div>
  </div>
</div>
