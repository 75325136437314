<div class="px-4 py-8 md:px-6 lg:px-8 h-full">
  <div
    class="flex flex-column lg:flex-row justify-content-center align-items-center gap-7 h-full">
    <div class="text-center lg:text-right">
      <div class="mt-6 mb-3 font-bold display-small">Are you lost?</div>
      <p class="heading-medium mt-0 mb-6">
        Sorry, we couldn't find the page or you do not have permission to access
        it.
      </p>
      <p-button
        type="button"
        label="Go back to home page"
        iconPos="left"
        icon="pi pi-arrow-left"
        styleClass="p-button-outlined"
        (click)="redirect()"></p-button>
    </div>
    <div>
      <img
        ngSrc="/assets/images/misc/feedback/404-rocket.png"
        alt="Image"
        class="w-full md:w-28rem img-object-contain"
        width="500"
        height="700"
        priority />
    </div>
  </div>
</div>
