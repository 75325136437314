import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AlertService } from '@harmanpa/ng-cae';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'inf-info-message',
  templateUrl: './info-message.component.html',
  standalone: true,
  imports: [ButtonModule, CommonModule, ToastModule],
})
export class InfoMessageComponent {
  @Input() icon: string;
  @Input() arrangement: 'horizontal' | 'vertical' = 'vertical';
  @Input() key: string = 'tempCard';
  @Input() title: string;
  @Input() message: string;
  @Input() actionBtn1Label: string;
  @Input() actionBtn2Label: string;
  @Input() actions: number = 1;
  @Input() closeBtn: boolean = true;
  // @Output() CTAchange: EventEmitter<Function> = new EventEmitter<Function>();
  show: boolean = true;

  constructor(
    private alertService: AlertService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {}

  onReject(): void {}
  onConfirm(): void {}

  // showinfmsg():void {
  // this.messageService.add({
  // key: 'customToast', // Use a unique key to identify this toast
  // severity: 'custom', // Use a custom severity to style the toast
  // life: 3000, // Duration of the toast in milliseconds
  // closable: true, // Whether the toast can be closed with the close button
  // data: { template: './info-message.component.html' }
  // });
  // }
}
