<div class="align-items-center flex">
  <img
    *ngIf="src"
    [alt]="alt"
    class="avatar img-object-contain"
    [class]="size"
    [src]="src"
    width="32"
    height="32" />
  <div
    *ngIf="!src"
    class="align-items-center avatar avatar-text body-large-semibold flex justify-content-center m-0"
    [class]="size">
    {{ initials }}
  </div>
  <span
    *ngIf="label"
    class="avatar-text mx-2"
    [ngClass]="{
      'body-large': size == 'large',
      'body-small': size == 'medium' || 'small'
    }">
    {{ label }}
  </span>
</div>
