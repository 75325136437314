<div
  id="team"
  class="surface-section px-4 py-8 md:px-6 lg:px-8">
  <div class="text-center font-bold text-primary heading-xlarge mb-3">
    The Team
  </div>
  <div class="text-center body-large landing-text-secondary mb-6">
    Meet the experts behind the platform, dedicated to helping your business
    succeed.
  </div>
  <div class="grid">
    <div class="col-12 md:col-3 text-center mb-5">
      <img
        ngSrc="assets/images/misc/avatars/ph.svg"
        class="mb-3 img-object-contain"
        height="140"
        width="132" />
      <div class="body-large text-medium text-primary mb-2">Peter Harman</div>
      <div class="text-blue-accent body-medium mb-3">
        Chief Executive Officer
      </div>
      <div class="inline-flex align-items-center">
        <a
          href="https://github.com/harmanpa"
          target="_blank"
          class="mr-3 text-600 cursor-pointer">
          <i class="pi pi-github text-xl"></i>
        </a>
        <a
          href="https://www.linkedin.com/in/peterharman/"
          target="_blank"
          class="text-600 cursor-pointer">
          <i class="pi pi-linkedin text-xl"></i>
        </a>
      </div>
    </div>
    <div class="col-12 md:col-3 text-center mb-5">
      <img
        ngSrc="assets/images/misc/avatars/xp.svg"
        class="mb-3img-object-contain"
        height="140"
        width="132" />
      <div class="body-large text-medium text-primary mb-2">Xènia Pie</div>
      <div class="text-blue-accent body-medium mb-3">Chief Product Officer</div>
      <div class="inline-flex align-items-center">
        <a
          href="https://github.com/xeniapie"
          target="_blank"
          class="mr-3 text-600 cursor-pointer">
          <i class="pi pi-github text-xl"></i>
        </a>
        <a
          href="https://www.linkedin.com/in/xeniapie/"
          target="_blank"
          class="text-600 cursor-pointer">
          <i class="pi pi-linkedin text-xl"></i>
        </a>
      </div>
    </div>
    <div class="col-12 md:col-3 text-center mb-5">
      <img
        src="assets/images/misc/avatars/sl.svg"
        class="mb-3"
        height="140" />
      <div class="body-large text-medium text-primary mb-2">Simon Leigh</div>
      <div class="text-blue-accent body-medium mb-3">
        Head of Customer Success
      </div>
      <div class="inline-flex align-items-center">
        <a
          href="https://www.linkedin.com/in/simon-leigh/"
          target="_blank"
          class="text-600 cursor-pointer">
          <i class="pi pi-linkedin text-xl"></i>
        </a>
      </div>
    </div>
    <div class="col-12 md:col-3 text-center mb-5">
      <img
        ngSrc="assets/images/misc/avatars/bo.svg"
        class="mb-3 img-object-contain"
        height="140"
        width="132" />
      <div class="body-large text-medium text-primary mb-2">Buse Ozkan</div>
      <div class="text-blue-accent body-medium mb-3">
        Full Stack Software Engineer
      </div>
      <div class="inline-flex align-items-center">
        <a
          href="https://github.com/buseozkan"
          target="_blank"
          class="mr-3 text-600 cursor-pointer">
          <i class="pi pi-github text-xl"></i>
        </a>
        <a
          href="https://www.linkedin.com/in/buse-ozkan-147a1a13a"
          target="_blank"
          class="text-600 cursor-pointer">
          <i class="pi pi-linkedin text-xl"></i>
        </a>
      </div>
    </div>
    <div class="col-12 md:col-3 text-center">
      <img
        ngSrc="assets/images/misc/avatars/lb.svg"
        class="mb-3 img-object-contain"
        height="140"
        width="132" />
      <div class="body-large text-medium text-primary mb-2">Luis Boavida</div>
      <div class="text-blue-accent body-medium mb-3">3D Software Engineer</div>
      <div class="inline-flex align-items-center">
        <!-- <a class="mr-3 text-600 cursor-pointer">
                    <i class="pi pi-twitter text-xl"></i>
                </a> -->
        <a
          href="https://github.com/LuisBoavida0"
          target="_blank"
          class="mr-3 text-600 cursor-pointer">
          <i class="pi pi-github text-xl"></i>
        </a>
        <a
          href="https://www.linkedin.com/in/luis-boavida/"
          target="_blank"
          class="text-600 cursor-pointer">
          <i class="pi pi-linkedin text-xl"></i>
        </a>
      </div>
    </div>
    <!-- <div class="col-12 md:col-6 text-center">
      <img src="assets/images/misc/avatars/sh.svg" class="mb-3" height="140" />
      <div class="body-large text-primary mb-3">Sarah Harman</div>
      <div class="text-blue-accent body-large mb-3">Company Administrator</div>
    </div> -->
  </div>
</div>
