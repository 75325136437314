import { CommonModule } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { BadgeModule } from 'primeng/badge';
import { DividerModule } from 'primeng/divider';
import { TabViewModule } from 'primeng/tabview';
import { NotificationContentComponent } from './notification-content/notification-content.component';
import {
  DisplayTaskGroup,
  DisplayTask,
} from '../notification-button.component';
import { maxBy, minBy, sumBy } from 'lodash-es';

@Component({
  selector: 'inf-notification-panel',
  imports: [
    BadgeModule,
    CommonModule,
    DividerModule,
    NotificationContentComponent,
    TabViewModule,
  ],
  templateUrl: './notification-panel.component.html',
  standalone: true,
})
export class NotificationPanelComponent {
  @Input() tasks: DisplayTask[] = [];
  @Input() integrations: DisplayTask[] = [];
  all: DisplayTaskGroup[] = [];
  /**the selected panel index */
  panelIndex = 0;

  /** Contains order of which task as priority (to set it on the group display) */
  statusPriority: { [key: string]: number } = {
    Failed: 1,
    Running: 2,
    Scheduled: 3,
    Submitted: 4,
    Complete: 5,
  };

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const all = this.tasks.concat(this.integrations);
    this.all = this.groupTasks(all);
  }

  ngOnInit(): void {
    const all = this.tasks.concat(this.integrations);
    this.all = this.groupTasks(all);
  }

  notificationPanelChange(event: any): void {
    this.panelIndex = event.index;
  }

  taskTrackBy(index: number, task: DisplayTask): string {
    return task.id;
  }

  /**
   * Groups tasks by their document id.
   * Converts DisplayTask into DisplayTaskGroup
   * @param tasks - The tasks
   * @returns - The tasks grouped
   */
  groupTasks(tasks: DisplayTask[]): DisplayTaskGroup[] {
    console.log('groupTasks: tasks as DisplayTask[]', tasks);
    //groups tasks
    const grouped = tasks.reduce((acc, task) => {
      acc[task.documentId] = acc[task.documentId] || [];
      acc[task.documentId].push(task);
      return acc;
    }, {} as { [documentId: string]: DisplayTask[] });

    //Loop groups to convert it into DisplayTaskGroup
    const groups = Object.keys(grouped).map(documentId => {
      //Get tasks from that group
      const children = grouped[documentId];
      const representativeTask = this.getRepresentativeTask(children);
      const started = new Date(minBy(children, t => t.started)?.started || 0);
      const updated = new Date(maxBy(children, t => t.updated)?.updated || 0);
      const progress =
        sumBy(children, t => t.progress || 0.0) / children.length;
      return {
        //Set DisplayTaskGroup values
        type: representativeTask.type,
        title: representativeTask.title,
        status: representativeTask.status,
        started,
        progress,
        updated,
        children,
        isRead: children.some(task => task.isRead),
        moduleName: representativeTask.moduleName,
      };
    });

    // Sort groups so that those with isRead false come first
    groups.sort((a, b) => Number(a.isRead) - Number(b.isRead));
    return groups;
  }

  /**
   * Gets the task that will be displayed on the group (the representative task)
   * @param tasks - the tasks
   * @returns - the choosen task to represent the group
   */
  getRepresentativeTask(tasks: DisplayTask[]): DisplayTask {
    // Sort by status priority and then progress if status is 'Running'
    tasks.sort((a, b) => {
      if (this.statusPriority[a.status] !== this.statusPriority[b.status]) {
        return this.statusPriority[a.status] - this.statusPriority[b.status];
      }
      if (a.status === 'Running') {
        return (a.progress || 0) - (b.progress || 0);
      }
      return 0;
    });
    return tasks[0];
  }
}
