<div
  class="align-items-center flex my-2 user-menu"
  (click)="toggleMenu()">
  <inf-avatar
    label="{{ user.name }}"
    [user]="user"
    [size]="'medium'"></inf-avatar>
  <i class="pi pi-chevron-down"></i>
</div>

<div
  *ngIf="menuOpen"
  class="flex flex-column user-menu-panel">
  <div class="flex flex-column gap-1">
    <inf-menu-item
      *ngFor="let item of userMenu.items; trackBy: itemTrackBy"
      (click)="toggleMenu()"
      [item]="item"
      [markActiveRoute]="false"></inf-menu-item>
  </div>
  <p-divider></p-divider>
  <inf-menu-item
    class="menu-item-version"
    *ngFor="let item of generalUserMenu.items; trackBy: itemTrackBy"
    (click)="toggleMenu()"
    [item]="item"
    [markActiveRoute]="false"></inf-menu-item>
</div>
