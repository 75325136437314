import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import {
  AlertService,
  PatchworkConfig,
  UserService,
  BugReportService,
} from '@harmanpa/ng-cae';
import { from, mergeMap, mapTo, tap } from 'rxjs';
import { TruncatePipe } from '../../pipes/truncate.pipe';

@Component({
  selector: 'inf-menu-item',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, TruncatePipe],
  templateUrl: './menu-item.component.html',
})
export class MenuItemComponent {
  @Input() item: MenuItem = {};
  @Input() markActiveRoute: boolean = true;

  constructor(
    private userService: UserService,
    private bugReportService: BugReportService,
    private alertService: AlertService
  ) {}

  onClick(item: MenuItem): void {
    item.expanded = !item.expanded;
    switch (item.label) {
      case 'Log out':
        this.logout();
        break;
      case 'Help':
        this.openGleap();
        break;
      case 'Support':
        //TODO: redirect to support template on Gleap
        this.openGleap();
        break;
      default:
        if (item.url) {
          this.openLanding(item);
        }
    }
  }

  logout(): void {
    this.userService
      .logout()
      .pipe(mergeMap(done => this.userService.forceReload('/', '/wait')))
      .subscribe({
        next: newMe => {
          // this.alertService.info('log out successfully', 'Logout');
        },
        error: err => this.alertService.error("can't log out", 'Logout Failed'),
      });
  }

  openGleap(): void {
    this.bugReportService.openHelpCenter(true);
  }

  openLanding(item: MenuItem): void {
    if (!item.url?.startsWith('http://') && !item.url?.startsWith('https://')) {
      // If URL doesnt start with http:// or https://, add it to the URL
      window.open(`https://${item.url}`, '_blank');
    } else {
      window.open(item.url, '_blank');
    }
  }

  childItemTrackBy(index: number, item: MenuItem): string {
    return item.label as string;
  }
}
