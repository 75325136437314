<div
  *ngIf="show"
  class="flex flex-initial align-items-center temp-card py-2 px-3"
  [class]="getType()">
  <div class="align-items-center flex icon justify-content-center mr-3">
    <i [class]="icon"></i>
  </div>

  <div
    class="flex justify-content-between align-items-center py-1 mr-3"
    [ngStyle]="{ width: '100%' }">
    <div class="flex flex-column">
      <p
        class="font-bold title mb-1"
        *ngIf="title">
        {{ title }}
      </p>
      <p>{{ message }}</p>
    </div>
    <div class="ml-auto flex align-items-center">
      <ng-container *ngIf="actionBtn && closeBtn">
        <p-button
          class="mr-2"
          styleClass="p-button-sm p-button-text"
          [label]="actionBtnLabel"
          (click)="emitAction()"></p-button>
        <p-button
          icon="pi pi-times"
          styleClass="p-button-sm p-button-rounded p-button-text"
          (click)="close()"></p-button>
      </ng-container>
      <ng-container *ngIf="actionBtn && !closeBtn">
        <p-button
          styleClass="p-button-sm p-button-text"
          [label]="actionBtnLabel"
          (click)="emitAction()"></p-button>
      </ng-container>
      <ng-container *ngIf="!actionBtn && closeBtn">
        <p-button
          icon="pi pi-times"
          styleClass="p-button-sm p-button-rounded p-button-text"
          (click)="close()"></p-button>
      </ng-container>
    </div>
  </div>
</div>
