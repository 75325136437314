import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  Resolve,
  Router,
  RouterStateSnapshot,
  TitleStrategy,
} from '@angular/router';
import { InProgressService, IntegrationService } from '@harmanpa/ng-cae';
import { Observable, map, combineLatest, startWith } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationsTitleService {
  constructor(
    private inProgress: InProgressService,
    private strategy: TitleStrategy,
    private integrations: IntegrationService
  ) {}

  start(): void {
    // Connect the title strategy to the InProgressService
    (this.strategy as TemplatePageTitleStrategy).setNotifications(
      combineLatest(
        this.integrations.watchIntegrations().pipe(map(ints => ints.length)),
        this.inProgress
          .observe()
          .pipe(map(notifications => notifications.length))
      ).pipe(
        map(ab => ab[0] + ab[1]),
        startWith(0)
      )
    );
  }
}

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
  private n = 0;
  private t = '';

  constructor(private title: Title) {
    super();
  }

  setNotifications(notifications: Observable<number>): void {
    notifications.subscribe(n => {
      this.n = n;
      this.setTitle();
    });
  }

  updateTitle(state: RouterStateSnapshot): void {
    const newTitle = this.buildTitle(state);
    this.t = newTitle || '';
    this.setTitle();
  }

  setTitle(): void {
    this.title.setTitle(
      `${this.n > 0 ? '(' + this.n + ') ' : ''}Infinitive${
        this.t.length > 0 ? ': ' : ''
      }${this.t} `
    );
  }
}
