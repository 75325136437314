<div class="card subscription-pricing">
  <p-table
    class="mb-3"
    [globalFilterFields]="['name']"
    [value]="tableValues">
    <ng-template pTemplate="header">
      <tr>
        <th>
          <div class="p-2">
            <h3 class="mb-2">Pricing</h3>
            <div class="block mb-4 text-secondary text-sm">
              Fringilla est ullamcorper eget nulla facilisi etiam
            </div>
            <div class="align-items-center flex gap-2 mb-2">
              <div>Monthly</div>
              <p-inputSwitch
                class="flex pricing-switch"
                [(ngModel)]="checked"></p-inputSwitch>
              <div>Yearly</div>
            </div>
          </div>
        </th>
        <th>
          <div
            class="align-items-center flex flex-column justify-content-start p-2">
            <h5 class="mb-2">Basic</h5>
            <div class="align-items-center flex flex-row gap-2 mb-2 pb-1">
              <h3 class="font-bold">$10</h3>
              <div class="text-secondary text-sm">/month</div>
            </div>
            <button
              class="p-button-accent"
              label="Buy Now"
              pButton></button>
          </div>
        </th>
        <th>
          <div
            class="align-items-center flex flex-column justify-content-start p-2">
            <h5 class="mb-2">Basic</h5>
            <div class="align-items-center flex flex-row gap-2 mb-2 pb-1">
              <h3 class="font-bold">$10</h3>
              <div class="text-secondary text-sm">/month</div>
            </div>
            <button
              label="Buy Now"
              pButton></button>
          </div>
        </th>
        <th>
          <div
            class="align-items-center flex flex-column justify-content-start p-2">
            <h5 class="mb-2">Basic</h5>
            <div class="align-items-center flex flex-row gap-2 mb-2 pb-1">
              <h3 class="font-bold">$10</h3>
              <div class="text-secondary text-sm">/month</div>
            </div>
            <button
              class="p-button-secondary"
              label="Contact Us"
              pButton></button>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-value>
      <tr>
        <td>{{ value.name }}</td>
        <td class="text-center">
          <i
            *ngIf="isValueBoolean(value.basic)"
            class="pi"
            [ngClass]="
              value.basic ? 'pi-check' : 'pi-times text-secondary'
            "></i>
          <div
            *ngIf="!isValueBoolean(value.basic)"
            class="text-secondary text-xs">
            {{ value.basic }}
          </div>
        </td>
        <td class="text-center">
          <i
            *ngIf="isValueBoolean(value.standard)"
            class="pi"
            [ngClass]="
              value.standard ? 'pi-check' : 'pi-times text-secondary'
            "></i>
          <div
            *ngIf="!isValueBoolean(value.standard)"
            class="text-secondary text-xs">
            {{ value.standard }}
          </div>
        </td>
        <td class="text-center">
          <i
            *ngIf="isValueBoolean(value.advanced)"
            class="pi"
            [ngClass]="
              value.advanced ? 'pi-check' : 'pi-times text-secondary'
            "></i>
          <div
            *ngIf="!isValueBoolean(value.advanced)"
            class="text-secondary text-xs">
            {{ value.advanced }}
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
