import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { OrdersStatusComponent } from '../orders/orders-status/orders-status.component';
import { PricingComponent } from '../shared/components/pricing/pricing.component';
import { ButtonModule } from 'primeng/button';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ProductsOverviewComponent } from '../products/products-overview/products-overview.component';
import { ZeigarnikComponent } from './zeigarnik/zeigarnik.component';
import { IntegrationsComponent } from '../shared/components/integrations/integrations.component';
import { HttpClient } from '@angular/common/http';
import {
  AlertService,
  DocumentSummary,
  StripeService,
  User,
  UserService,
  WorkspaceService,
} from '@harmanpa/ng-cae';
import {
  DefaultService,
  VersionedPublishedConfigurator,
  Workspace,
} from 'generated-src';
import { Title } from '@angular/platform-browser';
import { PushNotificationComponent } from '../shared/components/push-notification/push-notification.component';
import { FormGroup } from '@angular/forms';
import { TempCardComponent } from '../shared/components/temp-card/temp-card.component';
import { ConfiguratorsOverviewComponent } from '../configurators/configurators-overview/configurators-overview.component';
import { OrdersOverviewComponent } from '../orders/orders-overview/orders-overview.component';
import { BannerComponent } from '../shared/components/banner/banner.component';
import { Banner } from '../shared/model/banner.model';
import { TagComponent } from '../shared/components/tag/tag.component';
import { ThemeService } from '../shared/services/theme.service';
import { ThemeDirective } from '../shared/helpers/theme.directive';
@Component({
  selector: 'inf-dashboard',
  standalone: true,
  imports: [
    BannerComponent,
    ButtonModule,
    CommonModule,
    ConfiguratorsOverviewComponent,
    DividerModule,
    // InfoMessageComponent,
    IntegrationsComponent,
    OrdersOverviewComponent,
    OrdersStatusComponent,
    ProductsOverviewComponent,
    PricingComponent,
    PushNotificationComponent,
    RouterLink,
    TagComponent,
    TempCardComponent,
    ZeigarnikComponent,
    ThemeDirective,
  ],
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent {
  inProgress: boolean;
  products: DocumentSummary[];
  productsSyncing: boolean = false;
  user: User = { email: '', workspace: '' };
  subscription: string;
  currentWorkspace: Workspace;
  wsName: string = '';
  welcomePopup: boolean;

  banner: Banner = {
    title: 'Test Banner',
    message: 'this is a test for a banner',
    link: 'Learn more',
    href: 'www.google.com',
  };

  isLightTheme: boolean = true;
  publishedConfigurators: VersionedPublishedConfigurator[] = [];

  constructor(
    private route: ActivatedRoute,
    private alertService: AlertService,
    private api: DefaultService,
    private http: HttpClient,
    private stripe: StripeService,
    private titleService: Title,
    private userService: UserService,
    private workspaceService: WorkspaceService
  ) {}

  public ngOnInit(): void {
    this.inProgress = true;
    this.userService.me().subscribe(res => {
      this.user = res;
      this.getSubscription();
      // this.welcomePopup = !this.userService.getDataItem('welcome-popup') && this.user.signup_method==='password'
      this.welcomePopup = !this.userService.getDataItem('welcome-popup');
      this.inProgress = false;
    });
    this.route.data.subscribe(data => {
      const notificationCount = data['title'] as string;

      if (notificationCount) {
        this.titleService.setTitle(
          `${notificationCount} Infinitive: Dashboard - `
        );
      }
    });
    this.workspaceService.getWorkspace().subscribe({
      next: ws => {
        this.currentWorkspace = ws;
        if (ws.name) {
          this.wsName = ws.name.toUpperCase();
        } else {
          this.wsName = 'WORKSPACE';
        }
        //Set title (first letter uppercase, the rest is lowercase)
        this.titleService.setTitle(
          'Infinitive: Dashboard - ' +
            this.wsName.charAt(0).toUpperCase() +
            this.wsName.slice(1).toLowerCase()
        );
      },
      error: err => {
        this.alertService.error(err);
      },
    });
    this.getProducts();
    this.getConfigurators();
  }
  //todo getconfigurations

  public getProducts(): void {
    this.api.findModules().subscribe({
      next: products => {
        this.products = products;
        this.inProgress = false;
      },
      error: err => {
        this.alertService.error(err, 'Modules');
        this.productsSyncing = false;
      },
    });
  }

  public getConfigurators(): void {
    this.api.findPublishedConfigurators().subscribe({
      next: configurators => {
        this.publishedConfigurators = configurators;
        console.log(this.publishedConfigurators);
      },
      error: err => {
        this.alertService.error(err, 'Modules');
      },
    });
  }

  public getSubscription() {
    this.stripe.displaySubscription(true).subscribe({
      next: subscription => {
        this.subscription = Object.values(subscription)[0];
        console.log(this.subscription);
      },
      error: (err: string | object) => this.alertService.error(err),
    });
  }

  updateWorkspace(form: FormGroup): void {
    this.userService.setDataItem('welcome-popup', true);
    if (
      !form.pristine &&
      form.value['emails'] != null &&
      form.value['name'] != '' &&
      form.valid
    ) {
      this.inviteTeam(form);
    } else {
      this.closeModal();
    }
    // TODO: endpoint to change workspace name
    console.log('dashboard', form);
  }

  inviteTeam(form: FormGroup): void {
    const emailList = form.value['emails'].join(';');
    this.inProgress = true;
    if (!!emailList && form.valid) {
      this.userService.inviteUsers(emailList).subscribe({
        next: (newUsers: User[]) => {
          this.inProgress = false;
          this.alertService.success(
            'Invited ' + newUsers.length + ' new users',
            'Yay!'
          );
          this.closeModal();
        },
        error: (err: {}) => {
          this.inProgress = false;
          this.alertService.error(err, 'Oops!');
        },
      });
    } else {
      if (form.invalid) {
        this.alertService.error(
          'Looks like some data is not correct.',
          'Oops!'
        );
      }
    }
  }

  closeModal(): void {
    this.welcomePopup = false;
  }

  //TEST FOR TESTING SWAPPING THEMES
  // toggleTheme(){
  //   this.isLightTheme = !this.isLightTheme;
  //   const active = this.themeService.getActiveTheme();
  //   if (active.name === 'light') {
  //     this.themeService.setTheme('dark');
  //   } else {
  //     this.themeService.setTheme('light');
  //   }
  // }
}
