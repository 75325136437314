import { Routes } from '@angular/router';
import { IntegrationsTabComponent } from './integrations-tab/integrations-tab.component';
import { LayoutComponent } from './layout/layout.component';
import { OrdersComponent } from './orders/orders.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SubscriptionsOverviewComponent } from './subscriptions/subscriptions-overview/subscriptions-overview.component';
import { SubscriptionsUpgradeComponent } from './subscriptions/subscriptions-upgrade/subscriptions-upgrade.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { TestComponent } from './test/test.component';
import { LandingComponent } from './landing/landing.component';
import {
  AuthGuard,
  CanReadGuard,
  NoAuthGuard,
  PatchworkResolver,
  PermissionsResolver,
} from '@harmanpa/ng-cae';
import { WaitComponent } from './layout/wait/wait.component';
import { OAuthPageComponent } from './oauth-page/oauth-page.component';
import { WorkflowComponent } from './orders/workflow/workflow.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { SwaggerComponent } from './swagger/swagger.component';
import { ActivityComponent } from './dashboard/activity/activity.component';
import { OrderComponent } from './orders/order/order.component';
import { CustomersComponent } from './orders/customers/customers.component';
import { CustomerComponent } from './orders/customer/customer.component';
import { ThemeGenerator } from './configurators/settings/theme-generator/theme-generator.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: LandingComponent,
    pathMatch: 'full',
    canActivate: [NoAuthGuard],
    title: 'Join the Future of Choice',
    data: { isAuthURL: '/dashboard' },
  },
  {
    path: 'landing',
    component: LandingComponent,
    title: 'Join the Future of Choice',
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./products/products.routes').then(mod => mod.productsRoutes),
      },
      {
        path: 'configurators',
        loadChildren: () =>
          import('./configurators/configurators.routes').then(
            mod => mod.configuratorsRoutes
          ),
      },
      { path: 'orders', component: OrdersComponent, title: 'Orders' },
      {
        path: 'orders/:order_id',
        component: OrderComponent,
        title: 'Order',
        resolve: { order: PatchworkResolver, permissions: PermissionsResolver },
        canActivate: [CanReadGuard],
        data: { type: 'Order', menuType: 'NONE' },
      },
      { path: 'customers', component: CustomersComponent, title: 'Customers' },
      {
        path: 'customers/:customer_id',
        component: CustomerComponent,
        title: 'Customer',
      },
      { path: 'workflow', component: WorkflowComponent, title: 'Workflow' },
      {
        path: 'integrations',
        component: IntegrationsTabComponent,
        title: 'Integrations',
      },
      {
        path: 'subscription',
        component: SubscriptionsComponent,
        children: [
          {
            path: '',
            component: SubscriptionsOverviewComponent,
            title: 'Infinitive:',
          },
          {
            path: 'upgrade',
            component: SubscriptionsUpgradeComponent,
            title: 'Infinitive:',
          },
        ],
      },
      {
        path: 'admin-space',
        data: { menuType: 'ADMIN_MENU' },
        title: 'Admin Space',
        loadChildren: () =>
          import('./admin-space/admin-space.routes').then(
            mod => mod.adminSpaceRoutes
          ),
      },
      {
        path: 'activity',
        title: 'Activity',
        component: ActivityComponent,
      },
      { path: 'test', component: TestComponent, title: 'Test Env.' },
      { path: 'wait', component: WaitComponent, title: 'Loading...' },
    ],
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  { path: 'oauth', component: OAuthPageComponent },
  { path: '404', component: NotFoundComponent },
  {
    path: '',
    loadChildren: () =>
      import('./auth/auth.routes').then(mod => mod.authRoutes),
  },
  {
    path: 'swagger',
    component: SwaggerComponent,
    title: 'API',
    canActivate: [AuthGuard],
  },
  {
    path: 'thememanagement',
    component: ThemeGenerator,
    title: 'ThemeManagement',
    canActivate: [AuthGuard],
  },
  { path: 'denied', redirectTo: '/404', pathMatch: 'full' },
  { path: '**', redirectTo: '/404', pathMatch: 'full' },
];
