import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'inf-oauth-page',
  standalone: true,
  imports: [CommonModule, ButtonModule, NgOptimizedImage],
  templateUrl: './oauth-page.component.html',
})
export class OAuthPageComponent {}
