<div class="align-items-center flex flex-column">
  <img
    *ngIf="src"
    [alt]="title"
    class="mb-2 img-object-contain"
    [ngSrc]="src"
    height="full"
    width="full" />
  <h4 class="font-bold mb-2 text-secondary">{{ title }}</h4>
  <div class="text-secondary">{{ subtitle }}</div>
</div>
