import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { EmptyStateComponent } from '../../shared/components/empty-state/empty-state.component';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TagComponent } from '../../shared/components/tag/tag.component';
import { ButtonModule } from 'primeng/button';
import { SearchSystemComponent } from '../../shared/components/search-system/search-system.component';
import { PricingPlansComponent } from './pricing-plans/pricing-plans.component';

@Component({
  selector: 'inf-subscriptions-overview',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    DividerModule,
    EmptyStateComponent,
    FormsModule,
    InputTextModule,
    PricingPlansComponent,
    SearchSystemComponent,
    TableModule,
    TagComponent,
  ],
  templateUrl: './subscriptions-overview.component.html',
})
export class SubscriptionsOverviewComponent {}
