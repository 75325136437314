<div
  *ngIf="n > 0 && progress < 1.0"
  class="flex align-items-center">
  <div class="flex align-items-center tertiary-container px-3 radius-full">
    <i class="pi pi-spin pi-spinner on-tertiary-container"></i>
    <span class="ml-2 my-1 body-xtra-small-semibold on-tertiary-container">
      {{ 100 * progress | number : '1.0-0' }}% Completed
    </span>
  </div>
</div>
<ng-content *ngIf="n === 0 || progress >= 1.0"></ng-content>
