import {
  Component,
  ElementRef,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { Router, RouterModule } from '@angular/router';
import {
  UserService,
  WorkspaceService,
  Workspace,
  AlertService,
  UserListener,
  User,
} from '@harmanpa/ng-cae';
import { TooltipModule } from 'primeng/tooltip';
import { mapTo, mergeMap } from 'rxjs';
import { LettersAvatarComponent } from 'src/app/shared/components/letters-avatar/letters-avatar.component';

@Component({
  selector: 'inf-workspace-menu',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    DividerModule,
    RouterModule,
    TooltipModule,
    LettersAvatarComponent,
  ],
  templateUrl: './workspace-menu.component.html',
})
export class WorkspaceMenuComponent implements OnChanges, UserListener {
  currentWorkspace: Workspace;
  workspaces: Workspace[] = [];
  emptyWorkspaces = 3;
  /** Toggle chevrons */
  showChevrons = false;
  /** Sets disabled/enabled left chevron */
  clickableLeftChevron = false;
  /** Sets disabled/enabled right chevron */
  clickableRightChevron = true;
  /** Scrollable workspaces container elem */
  @ViewChild('workspacesContainer') workspacesContainerElem: ElementRef;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private userService: UserService,
    private workspaceService: WorkspaceService
  ) {
    this.userService.registerUserListener(this);
  }

  setUser(user: User): void {
    if (!!user) {
      this.ngOnInit();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.workspaceService.getWorkspace().subscribe({
      next: ws => {
        this.currentWorkspace = ws;
        // this.workspaces = [ws];
        // this.emptyWorkspaces = 3;
        this.workspaceService.getWorkspaces().subscribe({
          next: wss => {
            this.workspaces = wss;
            this.resetScroll();
            this.emptyWorkspaces = Math.max(4 - this.workspaces.length, 0);
            this.showChevrons = this.workspaces.length > 4;
          },
          error: err => this.alertService.error(err),
        });
      },
      error: err => this.alertService.error(err),
    });
  }

  changeWorkspace(workspace: Workspace, leave: boolean): void {
    if (this.currentWorkspace.alias != workspace.alias) {
      this.userService
        .switchWorkspace(workspace.alias as string, leave)
        .pipe(
          mergeMap(newMe =>
            this.userService
              .forceReload(this.router.url, '/wait')
              .pipe(mapTo(newMe))
          )
        )
        .subscribe({
          next: newMe => {
            this.alertService.info(
              'Switched to ' + workspace.name + ' workspace',
              'Workspace Changed'
            );
          },
          error: err =>
            this.alertService.error(err, 'Workspace Request Failed'),
        });
    }
  }

  /**
   * Scrolls the workspace container
   * @param slideRight - If it is to scroll right or left
   */
  scroll(slideRight: boolean): void {
    const containerElem = this.workspacesContainerElem.nativeElement;

    if (slideRight) {
      containerElem.scrollBy({
        top: 0,
        left: 48, //Size of a workspace
        behavior: 'smooth',
      });

      //If scroll reached end
      if (
        containerElem.scrollLeft + 50 >=
        containerElem.scrollWidth - containerElem.clientWidth
      ) {
        this.clickableRightChevron = false;
      }
      this.clickableLeftChevron = true;
    } else {
      containerElem.scrollBy({
        top: 0,
        left: -48,
        behavior: 'smooth',
      });

      //If scroll reached start
      if (containerElem.scrollLeft - 50 <= 0) {
        this.clickableLeftChevron = false;
      }
      this.clickableRightChevron = true;
    }
  }

  /**
   * Scrolls the workspace container to the beggining
   */
  resetScroll(): void {
    this.workspacesContainerElem.nativeElement.scrollBy({
      top: 0,
      left: -99999,
      behavior: 'smooth',
    });

    //If scroll reached start
    if (this.workspacesContainerElem.nativeElement.scrollLeft - 50 <= 0) {
      this.clickableLeftChevron = false;
    }
    this.clickableRightChevron = true;
  }

  workspaceTrackBy(index: number, workspace: Workspace): string {
    return workspace.alias || '';
  }
}
