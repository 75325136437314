import { Component } from '@angular/core';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { HttpClient } from '@angular/common/http';
import { ButtonModule } from 'primeng/button';
@Component({
  selector: 'inf-integrations-tab',
  standalone: true,
  imports: [ButtonModule, MarketplaceComponent],
  templateUrl: './integrations-tab.component.html',
})
export class IntegrationsTabComponent {
  customerData: any[] = [];

  constructor(private http: HttpClient) {}

  getAllCustomerData(): void {
    this.http.get<any[]>('/api/getAllCustomerData').subscribe(
      response => {
        this.customerData = response;
      },
      error => {
        console.error('Error retrieving customer data:', error);
      }
    );
  }

  getSKUMap(): void {
    this.http.get<any[]>(`/api/ecommerce/skuPrice`).subscribe({
      next: res => console.log(res, 'SKU MAP?'),
      error: err => console.log(err),
    });
  }

  createOrder(): void {
    const payload = {
      sku: '100014',
      quantity: 2,
      priceUrl: window.location.href, // Use the current website's URL as priceUrl
    };

    this.http.post<any>('/api/ecommerce/createOrder', payload).subscribe({
      next: res => {
        console.log('Order created:', res);
      },
      error: err => {
        console.error('Error creating order:', err);
      },
    });
  }
}
