<!-- 0 or 1 action -->
<div
  *ngIf="show && arrangement == 'vertical'"
  class="flex flex-grow-1 flex-column align-items-center info-message py-5 px-3 mb-4">
  <div class="align-items-center flex icon justify-content-center mb-2">
    <i [class]="'pi ' + icon"></i>
  </div>
  <div
    class="flex flex-column"
    [ngStyle]="{ width: '100%' }">
    <div class="flex flex-column text-center">
      <p
        class="font-bold title mb-1"
        *ngIf="title">
        {{ title }}
      </p>
      <p>{{ message }}</p>
    </div>
    <!-- <p-button *ngIf="closeBtn"
        class="ml-auto"
        styleClass="p-button-sm p-button-text no-hover p-button-rounded"
        icon="pi pi-times"></p-button> -->
    <div class="align-items-center mt-3">
      <div *ngIf="actions == 1">
        <p-button
          class="flex-grow-1"
          styleClass="p-button-sm p-button-outlined p-button-secondary"
          [label]="actionBtn1Label"></p-button>
      </div>
      <div
        *ngIf="actions == 2"
        class="flex">
        <p-button
          class="flex-grow-1"
          styleClass="p-button-sm p-button-outlined p-button-secondary"
          [label]="actionBtn1Label"></p-button>
        <p-button
          class="ml-2 flex-grow-1"
          styleClass="p-button-sm p-button-outlined p-button-secondary"
          [label]="actionBtn2Label"></p-button>
      </div>
    </div>
  </div>
</div>
<!-- 2 vertical and horizontal arrangement -->
<div
  *ngIf="show && arrangement == 'horizontal'"
  class="flex flex-column info-message py-5 px-3">
  <div class="flex flex-1">
    <div class="align-items-center flex icon justify-content-center mr-3">
      <i [class]="'pi ' + icon"></i>
    </div>
    <div class="flex flex-column flex-grow-1">
      <div class="flex mb-3">
        <div class="flex flex-column">
          <p
            class="font-bold title mb-1"
            *ngIf="title">
            {{ title }}
          </p>
          <p>{{ message }}</p>
        </div>
        <p-button
          class="ml-auto"
          styleClass="p-button-sm p-button-text no-hover p-button-rounded"
          icon="pi pi-times"></p-button>
      </div>

      <div class="flex">
        <p-button
          class="mr-2 flex-grow-1"
          styleClass="p-button-sm p-button-outlined"
          [label]="actionBtn1Label"></p-button>
        <p-button
          class="flex-grow-1"
          styleClass="p-button-sm p-button-secondary"
          [label]="actionBtn2Label"></p-button>
      </div>
    </div>
  </div>
</div>
