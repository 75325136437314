import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  FormsModule,
  FormBuilder,
  ReactiveFormsModule,
  Validators,
  FormGroup,
} from '@angular/forms';
import {
  UserService,
  WorkspaceService,
  Workspace,
  User,
  AlertService,
} from '@harmanpa/ng-cae';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { Observable, catchError, map, of } from 'rxjs';
import { ChipsModule } from 'primeng/chips';
import { ErrorComponent } from '../error/error.component';
import { Regex } from '../../helpers/regex.helper';

@Component({
  selector: 'inf-push-notification',
  templateUrl: './push-notification.component.html',
  standalone: true,
  imports: [
    ButtonModule,
    ChipsModule,
    CommonModule,
    ErrorComponent,
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
  ],
})
export class PushNotificationComponent {
  @Output() workspaceChange: EventEmitter<FormGroup> =
    new EventEmitter<FormGroup>();
  @Output() isModalOpenChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() isModalOpen: boolean = true;

  inProgress: boolean = false;

  constructor(
    private alertService: AlertService,
    private fb: FormBuilder,
    private userService: UserService,
    private workspaceService: WorkspaceService
  ) {}

  wsControl = this.fb.group({
    name: [{ value: '', disabled: this.inProgress }, [Validators.minLength(3)]],
    emails: [
      { value: [], disabled: this.inProgress },
      [Validators.pattern(Regex.EMAIL)],
    ],
  });

  ngOnInit(): void {
    this.wsControl.reset();
  }

  confirm(): void {
    this.workspaceChange.emit(this.wsControl);
    // this.closeModal();
  }

  cancel(): void {
    this.closeModal();
  }

  closeModal(): void {
    this.isModalOpen = false;
    this.isModalOpenChange.emit(this.isModalOpen);
  }
}
