<div class="grid">
  <div class="col">
    <div
      class="p-2"
      [style.background-color]="theme?.properties?.light.primary">
      Primary
    </div>
    <div
      class="p-2"
      [style.background-color]="theme?.properties?.light.onPrimary">
      On Primary
    </div>
  </div>
  <div class="col">
    <div
      class="p-2"
      [style.background-color]="theme?.properties?.light.secondary">
      Secondary
    </div>
    <div
      class="p-2"
      [style.background-color]="theme?.properties?.light.onSecondary">
      On Secondary
    </div>
  </div>
  <div class="col">
    <div
      class="p-2"
      [style.background-color]="theme?.properties?.light.tertiary">
      Tertiary
    </div>
    <div
      class="p-2"
      [style.background-color]="theme?.properties?.light.onTertiary">
      On Tertiary
    </div>
  </div>
  <div class="col">
    <div
      class="p-2"
      [style.background-color]="theme?.properties?.light.error">
      Error
    </div>
    <div
      class="p-2"
      [style.background-color]="theme?.properties?.light.onError">
      On Error
    </div>
  </div>
</div>
<div class="grid">
  <div class="col">
    <div
      class="p-2"
      [style.background-color]="theme?.properties?.light.primaryContainer">
      Primary Cont.
    </div>
    <div
      class="p-2"
      [style.background-color]="theme?.properties?.light.onPrimaryContainer">
      On Primary Cont.
    </div>
  </div>
  <div class="col">
    <div
      class="p-2"
      [style.background-color]="theme?.properties?.light.secondaryContainer">
      Secondary Cont.
    </div>
    <div
      class="p-2"
      [style.background-color]="theme?.properties?.light.onSecondaryContainer">
      On Secondary Cont.
    </div>
  </div>
  <div class="col">
    <div
      class="p-2"
      [style.background-color]="theme?.properties?.light.tertiaryContainer">
      Tertiary Cont.
    </div>
    <div
      class="p-2"
      [style.background-color]="theme?.properties?.light.onTertiaryContainer">
      On Tertiary Cont.
    </div>
  </div>
  <div class="col">
    <div
      class="p-2"
      [style.background-color]="theme?.properties?.light.errorContainer">
      Error Cont.
    </div>
    <div
      class="p-2"
      [style.background-color]="theme?.properties?.light.onErrorContainer">
      On Error Cont.
    </div>
  </div>
</div>
<div class="grid">
  <div class="col-8">
    <div class="grid">
      <div class="col-4">
        <div
          class="p-2"
          [style.background-color]="theme?.properties?.light.surfaceDim">
          Surface Dim
        </div>
      </div>
      <div class="col-4">
        <div
          class="p-2"
          [style.background-color]="theme?.properties?.light.surface">
          Surface
        </div>
      </div>
      <div class="col-4">
        <div
          class="p-2"
          [style.background-color]="theme?.properties?.light.surfaceBright">
          Surface Bright
        </div>
      </div>

      <div class="col-2">
        <div
          class="p-2"
          [style.background-color]="
            theme?.properties?.light.surfaceContainerLowest
          ">
          S. Cont. Lowest
        </div>
      </div>
      <div class="col-2">
        <div
          class="p-2"
          [style.background-color]="
            theme?.properties?.light.surfaceContainerLow
          ">
          S. Cont. Low
        </div>
      </div>
      <div class="col-2">
        <div
          class="p-2"
          [style.background-color]="theme?.properties?.light.surfaceContainer">
          S. Cont.
        </div>
      </div>
      <div class="col-2">
        <div
          class="p-2"
          [style.background-color]="
            theme?.properties?.light.surfaceContainerHigh
          ">
          S. Cont. High
        </div>
      </div>
      <div class="col-4">
        <div
          class="p-2"
          [style.background-color]="
            theme?.properties?.light.surfaceContainerHighest
          ">
          S. Cont. Highest
        </div>
      </div>

      <div class="col-3">
        <div
          class="p-2"
          [style.background-color]="theme?.properties?.light.onSurface">
          On Surface
        </div>
      </div>
      <div class="col-3">
        <div
          class="p-2"
          [style.background-color]="theme?.properties?.light.onSurfaceVariant">
          On Surf. Variant
        </div>
      </div>
      <div class="col-3">
        <div
          class="p-2"
          [style.background-color]="theme?.properties?.light.outline">
          Outline
        </div>
      </div>
      <div class="col-3">
        <div
          class="p-2"
          [style.background-color]="theme?.properties?.light.outlineVariant">
          Outline Variant
        </div>
      </div>
    </div>
  </div>

  <div class="col-4">
    <div class="grid">
      <div class="col-12">
        <div
          class="p-2"
          [style.background-color]="theme?.properties?.light.inverseOnSurface">
          inverseOnSurface
        </div>
      </div>
      <div class="col-12">
        <div
          class="p-2"
          [style.background-color]="theme?.properties?.light.inversePrimary">
          inversePrimary
        </div>
      </div>
      <div class="col-6">
        <div
          class="p-2"
          [style.background-color]="theme?.properties?.light.scrim">
          scrim
        </div>
      </div>
      <div class="col-6">
        <div
          class="p-2"
          [style.background-color]="theme?.properties?.light.shadow">
          shadow
        </div>
      </div>
    </div>
  </div>
</div>
