import { AfterViewInit, Component } from '@angular/core';
import { IntegrationService } from '@harmanpa/ng-cae';
import { Workspace } from '@harmanpa/ng-cae';
import { WorkspaceService } from '@harmanpa/ng-cae';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'inf-marketplace',
  standalone: true,
  templateUrl: './marketplace.component.html',
  styles: [],
})
export class MarketplaceComponent implements AfterViewInit {
  currentWorkspace: Workspace;
  message: string = '';
  configure: string | undefined = undefined;
  config: { [key: string]: string } = {};

  constructor(
    private integrationService: IntegrationService,
    private workspaceService: WorkspaceService,
    private route: ActivatedRoute
  ) {
    this.configure = this.route.snapshot.queryParams['configure'];
    if (this.configure) {
      Object.entries(this.route.snapshot.queryParams)
        .filter(entry => entry[0].startsWith('config.'))
        .forEach(entry => (this.config[entry[0].substring(7)] = entry[1]));
    }
  }

  ngAfterViewInit(): void {
    this.workspaceService.getWorkspace().subscribe({
      next: ws => {
        this.currentWorkspace = ws;
        console.log(this.currentWorkspace);

        if (ws.alias) {
          this.integrationService.showMarketplace(
            '#integration-marketplace-placeholder',
            ws.alias,
            this.configure,
            this.config,
            (status: string) => setTimeout(() => (this.message = status), 0)
          );
        }
      },
      error: err => {
        console.log(err);
      },
    });
  }
}
