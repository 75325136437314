<div class="oauth-page flex flex-column justify-content-center">
  <div class="flex align-items-center justify-content-center mb-5">
    <!-- <div class="img-container flex align-items-center justify-content-center"> 
            <img
            alt="Integration"
            class="integration"
            src="../../../assets/images/logos/cae.png" />
        </div>
        <div  class="flex align-items-center justify-content-center ml-5">
            <div class="parameter-color" style="background-color: #e0e0e0;"></div>
            <div class="ml-2 parameter-color" style="background-color: #e0e0e0;"></div>
            <div class="ml-2 parameter-color" style="background-color: #e0e0e0;"></div>
        </div> -->
    <div class="img-container flex align-items-center justify-content-center">
      <img
        alt="Integration"
        class="integration img-object-contain"
        width="80"
        height="80"
        ngSrc="/assets/images/logos/infinitive.png" />
    </div>
  </div>
  <div class="flex flex-column text-center">
    <!-- <p-button styleClass="p-button-rounded success-button" icon="pi pi-check"></p-button> -->
    <div class="mt-2 mb-2 align-items-center justify-content-center">
      <h3 class="font-bold">Authorisation Completed</h3>
      <h3 class="font-bold">Successfully</h3>
    </div>
    <p-button
      styleClass="p-button-rounded success-button"
      icon="pi pi-check"></p-button>

    <h5 class="mt-6 text-secondary">You may now close this page.</h5>
  </div>
</div>
