import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
import { ChartModule } from 'primeng/chart';
import { CountStatistics } from '../../services/statistics.service';

@Component({
  selector: 'inf-status-box',
  standalone: true,
  imports: [CommonModule, IconComponent, ChartModule],
  templateUrl: './status-box.component.html',
})
export class StatusBoxComponent {
  @Input() count: number = 0;
  @Input() icon: string = '';
  @Input() info: string = '';
  @Input() status: string = '';
  @Input() stats: CountStatistics;
  basicData: any;
  basicOptions: any;

  constructor() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    this.basicData = {
      labels: ['Q1', 'Q2', 'Q3', 'Q4'],
      datasets: [
        {
          label: 'Sales',
          data: [540, 325, 702, 620],
          backgroundColor: [
            'rgba(255, 159, 64, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(153, 102, 255, 0.2)',
          ],
          borderColor: [
            'rgb(255, 159, 64)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
          ],
          borderWidth: 1,
        },
      ],
    };

    this.basicOptions = {
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          display: true,
          ticks: { display: false },
        },
        y: {
          display: false,
        },
      },
    };
  }
}
