import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import {
  AlertService,
  BugReportService,
  StripeService,
} from '@harmanpa/ng-cae';

@Component({
  selector: 'inf-pricing-plans',
  standalone: true,
  imports: [ButtonModule, CommonModule, RouterOutlet],
  templateUrl: './pricing-plans.component.html',
})
export class PricingPlansComponent {
  subscription: string[] = [];
  plans: SubscriptionPlan[] = [];
  selectedPlan: string;

  constructor(
    private alertService: AlertService,
    private stripe: StripeService,
    private gleap: BugReportService
  ) {
    this.plans = [
      {
        name: 'Infinitive Free',
        description: 'Evaluate the future of choice',
        price: 0,
        features: ['Evaluate 3D Configurator', 'CAD import STEP/IFC/Onshape'],
      },
      {
        name: 'Infinitive Startup',
        description: 'Give your customers the future of choice',
        price: 750,
        features: [
          'Hosted 3D Configurator',
          'CAD import STEP/IFC/Onshape',
          'Order management',
          'E-Commerce/ERP integrations',
          'Up to £30k monthly transactions',
          'Up to 5 team members',
        ],
      },
      {
        name: 'Infinitive Growth',
        description: 'See how the future grows your business',
        price: 2500,
        features: [
          'Hosted 3D Configurator',
          'CAD import STEP/IFC/Onshape',
          'Order management',
          'E-Commerce/ERP integrations',
          'Up to £60k monthly transactions',
          'Up to 25 team members',
        ],
      },
      {
        name: 'Infinitive Enterprise',
        description: 'Lets make the future together',
        price: -1,
        features: [
          'Hosted 3D Configurator',
          'CAD import STEP/IFC/Onshape',
          'Order management',
          'E-Commerce/ERP integrations',
          'Up to £180k monthly transactions',
          'Unlimited team members',
          'Dedicated customer success team',
        ],
      },
    ];
  }

  ngOnInit(): void {
    this.stripe.displaySubscription(true).subscribe({
      next: subscription => {
        this.subscription = Object.values(subscription);
        this.selectedPlan = this.subscription[0];
      },
      error: (err: string | object) => this.alertService.error(err),
    });
    console.log(this.stripe);
  }

  portal(): void {
    this.stripe.openPortal().subscribe({
      next: _ => {},
      error: err => this.alertService.error(err),
    });
  }

  findOutMore(): void {
    this.gleap.openFlow('vjoqna');
  }

  planTrackBy(
    index: number,
    plan: {
      name: string;
      description: string;
      price: number;
      features: string[];
    }
  ): string {
    return plan.name;
  }
}

export interface SubscriptionPlan {
  name: string;
  description: string;
  price: number;
  features: string[];
}
