import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'inf-letters-avatar',
  standalone: true,
  templateUrl: './letters-avatar.component.svg',
})
export class LettersAvatarComponent implements OnChanges {
  @Input() size: number = 40;
  @Input() color?: string;
  @Input() textColor?: string;
  @Input() text?: string = '';
  @Input() strokeColor?: string;
  labelText: string;
  size2: number;
  fontSize: number;
  bgColor: string;
  fgColor: string;
  strokeWidth: string;

  ngOnChanges(changes: SimpleChanges): void {
    //
    const split = (this.text || '').trim().split(/\s+/);
    switch (split.length) {
      case 0:
        this.labelText = '';
        break;
      case 1:
        this.labelText = split[0].substr(0, 2).toUpperCase();
        break;
      default:
        this.labelText = (
          split[0].substr(0, 1) + split[1].substr(0, 1)
        ).toUpperCase();
    }
    if (this.strokeColor) {
      this.strokeWidth = '4';
    } else {
      this.strokeWidth = '';
    }
    this.size2 = this.size / 2;
    this.fontSize = Math.floor((this.size * 18) / 40);
    this.bgColor = this.color || '#000000';
    this.fgColor = this.textColor || '#ffffff';
  }
}
