import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Customer, DocumentSummary } from '../../../../generated-src';
import { TableModule } from 'primeng/table';
import { SearchSystemComponent } from '../../shared/components/search-system/search-system.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { EmptyStateComponent } from '../../shared/components/empty-state/empty-state.component';
import { ButtonModule } from 'primeng/button';
import * as L from 'leaflet';
import { ChartModule } from 'primeng/chart';
import { UAParser } from 'ua-parser-js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
Chart.register(ChartDataLabels);
import { map } from 'rxjs/operators';
import { HttpClientModule } from '@angular/common/http';
import {
  CountStatistics,
  StatisticsService,
} from 'src/app/shared/services/statistics.service';
import { Workspace, WorkspaceService } from '@harmanpa/ng-cae';

@Component({
  selector: 'inf-customers',
  templateUrl: './customers.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    SearchSystemComponent,
    RouterModule,
    EmptyStateComponent,
    ButtonModule,
    ChartModule,
    HttpClientModule,
  ],
})
export class CustomersComponent implements OnInit {
  @Input() showDetails: boolean = true;
  @Input() rowsNumber: number = 10;
  customers: Customer[] = [];
  includeSearch: boolean = true;
  selectCustomers: boolean = false;
  loading: boolean = true;
  browserData: { [key: string]: number } = {};
  locations: { lat: number; lng: number }[] = [];
  browserUsageData: any;
  countryData: { [key: string]: number } = {};
  countryChartData: any;
  productUsageStatistics: { [key: string]: number } = {};
  productUsageStatisticsArray: { productId: string; usageCount: number }[] = [];
  currentWorkspace: Workspace;
  configurationCount: CountStatistics | null = null;

  constructor(
    private http: HttpClient,
    private statisticsService: StatisticsService,
    private workspaceService: WorkspaceService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.http.get<Customer[]>('/api/customers').subscribe(res => {
      console.log('API Response:', res); // Debugging step
      this.customers = res;
      this.loading = false;
      this.processCustomerData();
    });
  }

  get totalCustomers(): number {
    return this.customers.length;
  }

  getCurrentWorkspace(): void {
    this.workspaceService.getWorkspace().subscribe({
      next: (ws: Workspace) => {
        this.currentWorkspace = ws;
        console.log('Current Workspace:', this.currentWorkspace);

        // Fetch product usage statistics after getting the workspace
        this.getProductUsageStatistics();
        if (this.currentWorkspace.alias) {
          this.loadProductUsageStatistics(this.currentWorkspace.alias);
        }
      },
      error: (err: Error) => {
        console.error('Error fetching workspace:', err);
      },
    });
  }

  loadProductUsageStatistics(workspace: string): void {
    this.statisticsService.getProductUsageStatistics(workspace).subscribe(
      data => {
        this.productUsageStatistics = data;
      },
      error => {
        console.error('Error fetching product usage statistics:', error);
      }
    );
  }

  loadConfigurationCount(): void {
    this.statisticsService.getConfigurationCount().subscribe(
      data => {
        this.configurationCount = data;
      },
      error => {
        console.error('Error fetching configuration count:', error);
      }
    );
  }

  getProductUsageStatistics(): void {
    this.statisticsService
      .getProductUsageStatistics(this.currentWorkspace.alias ?? '')
      .subscribe({
        next: (data: { [ket: string]: number }) => {
          this.productUsageStatistics = data;
          this.productUsageStatisticsArray = Object.entries(data).map(
            ([productId, usageCount]) => ({ productId, usageCount })
          );
          console.log(
            'Product Usage Statistics:',
            this.productUsageStatisticsArray
          );
        },
        error: err => {
          console.log(err);
        },
      });
  }

  processCustomerData(): void {
    const browserData: { [key: string]: number } = {};
    const parser = new UAParser();

    this.customers.forEach(customer => {
      customer.user?.sessions?.forEach(session => {
        const latLong = session.latLong;
        if (latLong) {
          const [lat, lng] = latLong.split(',').map(Number);
          if (!isNaN(lat) && !isNaN(lng)) {
            this.locations.push({ lat, lng });
          }
        }

        const userAgent = session.userAgent;
        if (userAgent) {
          parser.setUA(userAgent);
          const browserName = parser.getBrowser().name;

          if (
            browserName &&
            !browserName.includes('Headless') &&
            !browserName.includes('bot')
          ) {
            browserData[browserName] = (browserData[browserName] || 0) + 1;
          }
        }
      });
    });

    const total = Object.values(browserData).reduce(
      (acc, count) => acc + count,
      0
    );

    this.browserUsageData = {
      labels: Object.keys(browserData),
      datasets: [
        {
          data: Object.values(browserData),
          backgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#66BB6A',
            '#FFA726',
          ],
        },
      ],
      options: {
        plugins: {
          datalabels: {
            formatter: (value: number) => {
              const percentage = ((value / total) * 100).toFixed(2);
              return `${percentage}%`;
            },
            color: '#fff',
            anchor: 'end',
            align: 'start',
          },
        },
      },
    };
  }

  initMap(): void {
    const map = L.map('customerMap').setView([0, 0], 2);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors',
    }).addTo(map);

    this.locations.forEach(location => {
      L.marker([location.lat, location.lng]).addTo(map);
    });

    if (this.locations.length) {
      const bounds = L.latLngBounds(
        this.locations.map(loc => [loc.lat, loc.lng])
      );
      map.fitBounds(bounds);
    }
  }

  // processCountryData(): void {
  //   const countryData: { [key: string]: number } = {};

  //   this.customers.forEach(customer => {
  //     customer.user?.sessions?.forEach(session => {
  //       const latLong = session.latLong?.split(',').map(Number);
  //       if (latLong) {
  //         this.getCountryFromLatLong(latLong[0], latLong[1]).subscribe(
  //           country => {
  //             if (country) {
  //               countryData[country] = (countryData[country] || 0) + 1;
  //               this.updateChart(countryData);
  //             }
  //           }
  //         );
  //       }
  //     });
  //   });
  // }

  getCountryFromLatLong(lat: number, long: number) {
    const url = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${long}&format=json`;
    return this.http.get<any>(url).pipe(map(res => res.address?.country));
  }

  updateChart(countryData: { [key: string]: number }) {
    this.countryChartData = {
      labels: Object.keys(countryData),
      datasets: [
        {
          label: 'Customers by Country',
          data: Object.values(countryData),
          backgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#66BB6A',
            '#FFA726',
          ],
        },
      ],
    };
  }
}
