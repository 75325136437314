import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { NgOptimizedImage } from '@angular/common';

@Component({
  templateUrl: './team.component.html',
  selector: 'inf-landing-team',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    NgOptimizedImage,
    RippleModule,
    StyleClassModule,
  ],
})
export class LandingTeamComponent {}
