import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, AbstractControlDirective } from '@angular/forms';

@Component({
  selector: 'inf-error',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error.component.html',
})
export class ErrorComponent {
  @Input() control!: AbstractControl | AbstractControlDirective | null;

  errorsList: string[] = [];
  errorMessages: { [name: string]: (param: any) => string } = {
    pattern: () => `Invalid value`,
    minlength: () => `Value is too short`,
    maxlength: () => `Value is too long`,
    password: () =>
      `Password must be at least 8 characters long, must include lower and upper case characters and at least one number`,
    passwordMatching: () => `Passwords don't match`,
    required: () => `This field is required`,
    passwordMismatch: () => `Passwords don't match`,
  };

  getErrorList(): string[] {
    if (this.control && this.control.errors) {
      this.errorsList = [];
      Object.keys(this.control.errors).map(error => {
        if (this.control) {
          this.control.touched || this.control.dirty
            ? this.errorsList.push(
                this.errorMessages[error](this.control.errors![error])
              )
            : '';
        }
      });
      return this.errorsList;
    }
    return [];
  }

  messageTrackBy(index: number, message: string): number {
    return index;
  }
}
