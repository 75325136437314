<p-divider (onChange)="notificationPanelChange($event)"></p-divider>
<div class="flex-column">
  <div
    *ngIf="!all.length"
    class="flex h-full">
    <p class="body-medium font-medium no-notification-text text-center m-auto">
      All clear
    </p>
  </div>
  <div *ngFor="let task of all; let last = last; trackBy: taskTrackBy">
    <inf-notification-content
      [task]="task"
      [last]="last"></inf-notification-content>
  </div>
</div>

<!-- 

<p-tabView class="tab-styling" (onChange)="notificationPanelChange($event)">
    <p-tabPanel [class.active-panel]="panelIndex === 0">
        <ng-template pTemplate="header">                    
            <span class="body-small flex justify-content-center align-items-center text-center w-full">
                All<p-badge *ngIf="all.length" class="ml-2" styleClass="p-0 body-xtra-small-semibold font-bold" [value]="all.length"></p-badge>          
            </span>      
        </ng-template>

        <p-divider [styleClass]="'header-divider'"></p-divider>
        <div *ngIf="!all.length" class="flex h-full flex-column">
            <p class="body-medium font-medium no-notification-text text-center m-auto">All clear</p>
        </div>
        <div *ngFor="let task of all; let last = last;">
            <inf-notification-content [task]="task" [last]="last"></inf-notification-content>
        </div>
    </p-tabPanel>
    <p-tabPanel [class.active-panel]="panelIndex === 1">
        <ng-template pTemplate="header">                    
            <span class="body-small flex justify-content-center align-items-center text-center w-full">
                Tasks<p-badge *ngIf="tasks.length" class="ml-2" styleClass="p-0 body-xtra-small-semibold font-bold" [value]="tasks.length"></p-badge>          
            </span>      
        </ng-template>

        <p-divider [styleClass]="'header-divider'"></p-divider>
        <div *ngIf="!tasks.length" class="flex h-full flex-column">
            <p class="body-medium font-medium no-notification-text text-center m-auto">All clear</p>
        </div>
        <div *ngFor="let task of tasks; let last = last;">
            <inf-notification-content [task]="task" [last]="last"></inf-notification-content>
        </div>
    </p-tabPanel>
    <p-tabPanel [class.active-panel]="panelIndex === 2">
        <ng-template pTemplate="header">                    
            <span class="body-small flex justify-content-center align-items-center text-center w-full">
                Integrations<p-badge *ngIf="integrations.length" class="ml-2" styleClass="p-0 body-xtra-small-semibold font-bold" [value]="integrations.length"></p-badge>          
            </span>      
        </ng-template>
        
        <p-divider [styleClass]="'header-divider'"></p-divider>
        <div *ngIf="!integrations.length" class="flex h-full flex-column">
            <p class="body-medium font-medium no-notification-text text-center m-auto">All clear</p>
        </div>
        <div *ngFor="let integration of integrations; let last = last;">
            <inf-notification-content [task]="integration" [last]="last"></inf-notification-content>
        </div>
    </p-tabPanel>
</p-tabView> -->
