<div
  class="border-round-lg flex align-items-center pricing-card"
  *ngIf="currentWorkspace">
  <div class="flex align-items-center pricing-ws-container">
    <inf-letters-avatar
      class="pricing-workspace"
      tooltipPosition="top"
      [size]="64"
      [text]="currentWorkspace.name"
      [color]="currentWorkspace.color" />
  </div>
  <div class="ml-3">
    <h3 class="body-large-semibold">Your Space</h3>
    <p class="body-small mb-2">You've used 30% of your space so far.</p>
    <p-progressBar
      class="w-full"
      [showValue]="false"
      [value]="25"></p-progressBar>
    <p class="text-secondary mt-1 body-xtra-small">
      3 configurations / 10 configurations
    </p>
  </div>
</div>

<!-- <div class="border-round-lg pricing">
  <div class="border-round-top-lg pricing-header">
    <div class="flex gap-2 mb-3">
      <h2 class="flex-1 font-bold">{{ name }}</h2>
      <button
        class="flex-1"
        [label]="pricing"
        pButton></button>
    </div>
    <div class="description text-xs">{{ description }}</div>
  </div>
  <div class="content">
    <div class="font-medium mb-3 title">Features</div>
    <div class="flex flex-column gap-2">
      <div class="align-items-center flex">
        <i class="mr-2 pi pi-reply"></i>
        <span class="font-medium label text-xs">{{ includes }}</span>
      </div>
      <div
        *ngFor="let feature of features"
        class="align-items-center flex">
        <i class="mr-2 pi pi-check-circle"></i>
        <span class="label text-xs">{{ feature }}</span>
      </div>
    </div>
    <button
      class="w-full"
      label="Upgrade Now!"
      pButton
      [routerLink]="['/subscription', 'upgrade']"></button>
  </div>
</div> -->
<!-- <div class="border-round-lg flex flex-column gap-4 px-4 py-3 pricing"> -->
<!-- <div class="flex align-items-center"> -->
<!-- <div class="flex align-items-center"> -->
<!-- <i class="mr-2 pi pi-euro"></i> -->
<!-- <img -->
<!-- alt="pricing" -->
<!-- class="mr-2" -->
<!-- src="../../../assets/images/misc/pricing-icon.png" /> -->
<!-- <h2 class="mr-6 pr-1 font-bold">Basic</h2> -->
<!-- </div> -->
<!-- <div class="font-medium text-right text-sm">{{ description }}</div> -->
<!-- </div> -->
<!-- <button -->
<!-- class="p-button-lg w-full" -->
<!-- label="Upgrade Now!" -->
<!-- pButton -->
<!-- [routerLink]="['/subscription', 'upgrade']"></button> -->
<!-- </div> -->
