import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusBoxComponent } from '../../shared/components/status-box/status-box.component';
import { StatisticsService } from '../../shared/services/statistics.service';

@Component({
  selector: 'inf-orders-status',
  standalone: true,
  imports: [CommonModule, StatusBoxComponent],
  templateUrl: './orders-status.component.html',
})
export class OrdersStatusComponent implements OnInit {
  @Input() dashboard: boolean = false;

  constructor(private statistics: StatisticsService) {}

  ngOnInit(): void {
    // fetch stats
    // can stats be specified by query parameters?
  }
}
