import { Component, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { Router } from '@angular/router';
import { NgOptimizedImage } from '@angular/common';
import { AuthService } from '@harmanpa/ng-cae';

@Component({
  templateUrl: './navbar.component.html',
  selector: 'inf-landing-navbar',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    RippleModule,
    NgOptimizedImage,
    StyleClassModule,
  ],
})
export class LandingNavBarComponent {
  isMenuHidden: boolean = true;
  small: boolean;
  screenHeight: number;
  screenWidth: number;

  constructor(private router: Router, public authService: AuthService) {
    this.getScreenSize();
  }

  onLoginButtonClick(): void {
    this.router.navigate(['/dashboard']);
  }

  onLogoClick(): void {
    this.onClickScroll('hero');
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 425) {
      this.small = true;
      this.isMenuHidden = true;
      // this.toggleNav();
    } else {
      this.small = false;
    }
  }

  onClickScroll(elementId: string): void {
    console.log(elementId);
    document.getElementById(elementId)?.scrollIntoView({ behavior: 'smooth' });
  }

  toggleNav(): void {
    this.isMenuHidden = !this.isMenuHidden;
  }
}
