<header class="align-items-center fixed flex header justify-content-end px-5">
  <ng-content></ng-content>

  <!-- <cae-notification-button class="notification" *ngIf="includeNotifications" [autoOpenNotifications]="false" (notificationsPanelChange)="notificationOpened()">
  </cae-notification-button> -->

  <inf-notification-button
    *ngIf="includeNotifications"
    [autoOpenNotifications]="false"
    (notificationsPanelChange)="notificationOpened()">
  </inf-notification-button>

  <inf-user-menu [(menuOpen)]="menuOpen"></inf-user-menu>
</header>
