import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { BadgeModule } from 'primeng/badge';
import {
  DefaultService,
  DocumentSummary,
  VersionedPublishedConfigurator,
} from 'generated-src';
import { SincePipe } from '@harmanpa/ng-cae';
import { Router } from '@angular/router';

@Component({
  selector: 'inf-configurators-list',
  standalone: true,
  imports: [BadgeModule, ButtonModule, CommonModule, MenuModule, SincePipe],
  templateUrl: './configurators-list.component.html',
})
export class ConfiguratorsListComponent {
  deployment: string | null;
  items: MenuItem[] = [];
  @Input() configurator: DocumentSummary;
  @Input() publishedConfigurator: VersionedPublishedConfigurator | undefined;

  constructor(private router: Router) {}

  ngOnInit(): void {
    // console.log('configurator list component' ,this.configurator);
    this.deployment = this.getDeployment();
    // console.log('configurator list component' , this.deployment);
    this.items = [
      {
        label: 'edit',
        icon: 'pi pi-pencil',
        command: event => this.edit(this.configurator),
      },
    ];
    if (this.publishedConfigurator != undefined) {
      this.items.push({
        label: 'open',
        icon: 'pi pi-arrow-up-right',
        command: event => this.open(this.publishedConfigurator),
      });
    }
  }

  edit(configurator: DocumentSummary): void {
    // console.log('configurator list component', configurator);
    this.router.navigate(['/configurators', configurator.id]);
  }

  open(
    publishedConfigurator: VersionedPublishedConfigurator | undefined
  ): void {
    if (publishedConfigurator) {
      const url =
        'https://' +
        (publishedConfigurator?.document?.customerDomain ||
          publishedConfigurator?.document?.infinitiveDomain);
      window.open(url, '_blank');
    }
  }

  getDeployment(): string | null {
    let array =
      this.publishedConfigurator?.document?.infinitiveDomain?.split('.');
    if (array) {
      return array[2];
    }
    return null;
  }
}
