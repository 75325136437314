import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  Route,
  Router,
  RouterOutlet,
  RouterStateSnapshot,
  TitleStrategy,
} from '@angular/router';
import { LandingNavBarComponent } from './navbar/navbar.component';
import { LandingHeroComponent } from './hero/hero.component';
import { LandingFeatureComponent } from './feature/feature.component';
import { LandingCTAComponent } from './cta/cta.component';
import { LandingLogoCloudsComponent } from './logo-clouds/logo-clouds.component';
import { LandingContactComponent } from './contact/contact.component';
import { LandingPricingComponent } from './pricing/pricing.component';
import { LandingTeamComponent } from './team/team.component';
import { LandingFooterComponent } from './footer/footer.component';
import { LandingTestimonialsComponent } from './testimonials/testimonials.component';
import { LandingSolutionsComponent } from './solutions/solutions.component';
import { LandingIntegrationsComponent } from './integrations/integrations.component';
@Component({
  selector: 'inf-landing',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    LandingNavBarComponent,
    LandingHeroComponent,
    LandingFeatureComponent,
    LandingCTAComponent,
    LandingIntegrationsComponent,
    LandingLogoCloudsComponent,
    LandingContactComponent,
    LandingTeamComponent,
    LandingFooterComponent,
    LandingSolutionsComponent,
    LandingPricingComponent,
    LandingTestimonialsComponent,
  ],
  templateUrl: './landing.component.html',
  /*Added overflow-x: unset; for scrollbar to be above header.
    If this is to be applied to every page, put this on _base.scss*/
  styles: [
    `
      ::ng-deep body {
        overflow-x: unset;
      }
    `,
  ],
})
export class LandingComponent {
  constructor() {}
}
