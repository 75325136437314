import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgPatchworkComponent } from '@harmanpa/ng-cae';
import { NotificationsTitleService } from './shared/services/notifications-title.service';
// import { ThemeDirective } from './shared/helpers/theme.directive';

@Component({
  selector: 'inf-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, NgPatchworkComponent],
})
export class AppComponent implements OnInit {
  constructor(private titleService: NotificationsTitleService) {}

  ngOnInit(): void {
    this.titleService.start();
  }

  title = 'infinitive-platform';
}
