<span
  class="border-round-3xl font-medium tag text-xs"
  [class]="status + ' ' + classes"
  [class.small]="isSmall"
  [ngStyle]="style">
  {{
    isSmall === undefined
      ? label
      : isSmall
      ? (label | lowercase)
      : (label | uppercase)
  }}
  <ng-content></ng-content>
</span>
