import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AlertService, DocumentSummary } from '@harmanpa/ng-cae';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { HttpClient } from '@angular/common/http';
import { DefaultService } from 'generated-src';
import { MenuItem } from 'primeng/api';
import { Menu, MenuModule } from 'primeng/menu';
import { forkJoin, map, of, switchMap, timer } from 'rxjs';
import { TagComponent } from '../../tag/tag.component';

@Component({
  selector: 'inf-toolbar-batch-categories',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    InputTextModule,
    OverlayPanelModule,
    TagComponent,
    MenuModule,
  ],
  templateUrl: './toolbar-batch-categories.component.html',
})
export class ToolbarBatchCategoriesComponent implements OnInit {
  /** All products */
  @Input() products: DocumentSummary[] = [];
  /** Selected products */
  @Input() selectedProducts: DocumentSummary[] = [];
  /** Emitter to know when overlay panel is closed */
  @Output() categoryClose = new EventEmitter<void>();

  @ViewChild('categoryOp') categoryOp: OverlayPanel;
  @ViewChild('extraOptionsMenu') extraOptionsMenu: Menu;
  @ViewChildren('searchCategoryInput')
  searchCategoryInput: QueryList<ElementRef>;

  //Categories variables
  /** Contains category input value */
  categoryInput: string = '';
  /** Contains all categories */
  categories: string[] = [];
  /** Contains categories divided in suggestions and the ones in common with the selected products */
  categoriesFiltered: { suggestions: string[]; common: string[] } = {
    suggestions: [],
    common: [],
  };
  /** Current suggestions according to the input */
  categoriesSuggestions: string[] = [];
  /** Category on which the extra option button was clicked */
  extraOptionsCategory = '';
  /** If it is renaming the category */
  isRenamingCategory = false;
  /** Contains the category renaming string */
  renamingCategoryStr = '';
  /** Contains the extra menu options */
  extraMenuOptions: MenuItem[] = [];

  constructor(
    private http: HttpClient,
    private api: DefaultService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.extraMenuOptions = [
      {
        label: 'Rename',
        icon: 'pi pi-pencil',
        iconClass: 'mr-0 body-xtra-small',
        command: event => this.extraOptionClick('edit'),
      },
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        iconClass: 'mr-0 body-xtra-small',
        styleClass: 'option-danger',
        command: event => this.extraOptionClick('delete'),
      },
    ];

    this.getCategories();
  }

  /**
   * On key change on input, filter categories
   */
  searchCategory(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.addCategory(this.categoryInput);
    } else if (event.key === ',') {
      this.addCategory(this.categoryInput.slice(0, -1));
    } else {
      //Get recommended categories
      this.categoriesSuggestions = this.categoriesFiltered.suggestions.filter(
        category =>
          category !== this.categoryInput &&
          category.toLowerCase().includes(this.categoryInput.toLowerCase())
      );
    }
  }

  /**
   * Gets categories
   * @param updateCategories - if it is to reset categories values after we get the new categories
   */
  getCategories(updateCategories = false): void {
    this.http.get<string[]>('/api/categories').subscribe({
      next: data => {
        this.categories = data;
        if (updateCategories) {
          this.resetCategoriesSuggestions();
        }
      },
      error: e => {
        console.log('Error fetching categories:', e);
      },
    });
  }

  /**
   * Adds category
   * @param category the category name
   */
  addCategory(category: string): void {
    this.selectedProducts.forEach((product, i) => {
      //Get module (to have categories)
      this.api
        .getModule(product.id as string, product.head as string)
        .subscribe(vModule => {
          if (vModule?.document) {
            //Add category
            vModule.document.categories ??= [];
            if (!vModule.document.categories.includes(category)) {
              vModule.document.categories.push(category);
            }

            //update module (with new categories)
            this.api
              .updateModule(
                vModule.id as string,
                vModule.version as string,
                vModule.document
              )
              .subscribe(module => {
                //Update version
                product.head = module.version;
                //Update category
                product.data ??= [];
                product.data['categories'] = vModule.document?.categories;

                if (i === this.selectedProducts.length - 1) {
                  //If Last category, send message and remove selections
                  this.alertService.success(
                    `Added category to selected product${
                      this.selectedProducts.length > 1 ? 's' : ''
                    } successfully`
                  );
                  this.getCategories(true);
                }
              });
          }
        });
    });
  }

  /**
   * Opens the category panel
   */
  openCategory(event: Event): void {
    this.resetCategoriesSuggestions();
    this.categoryOp.toggle(event);
  }

  /**
   * Deletes a category
   * @param category the category name
   * @param products the products where it is to delete the category
   */
  deleteCategory(category: string, products: DocumentSummary[]): void {
    //Use a map of observables to be able to send a success message when all of them finishes
    const updateModules = products.map(product =>
      this.api.getModule(product.id as string, product.head as string).pipe(
        switchMap(vModule => {
          if (
            vModule?.document &&
            (vModule.document.categories ?? []).includes(category)
          ) {
            vModule.document.categories = vModule.document.categories?.filter(
              _category => _category !== category
            );

            // Return an observable of the updateModule
            return this.api
              .updateModule(
                vModule.id as string,
                vModule.version as string,
                vModule.document
              )
              .pipe(
                map(module => {
                  //Update version and category data
                  product.head = module.version;
                  product.data ??= [];
                  product.data['categories'] = vModule.document?.categories;
                  return module;
                })
              );
          } else {
            return of(null);
          }
        })
      )
    );

    // Wait for all update operations to complete
    forkJoin(updateModules).subscribe({
      next: () => {
        this.alertService.success(`Category successfully deleted`);
        this.getCategories(true);
      },
    });
  }

  /**
   * On key change on input, to rename category
   */
  renameCategory(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      //Rename category
      //Use a map of observables to be able to send a success message when all of them finishes
      const renameModules = this.products.map(product =>
        this.api.getModule(product.id as string, product.head as string).pipe(
          switchMap(vModule => {
            if (
              vModule?.document &&
              (vModule.document.categories ?? []).includes(
                this.extraOptionsCategory
              )
            ) {
              //Remove category
              vModule.document.categories = vModule.document.categories?.filter(
                _category => _category !== this.extraOptionsCategory
              );

              //Add category
              if (
                !vModule.document.categories?.includes(this.renamingCategoryStr)
              ) {
                vModule.document.categories?.push(this.renamingCategoryStr);
              }

              // Return an observable of the updateModule
              return this.api
                .updateModule(
                  vModule.id as string,
                  vModule.version as string,
                  vModule.document
                )
                .pipe(
                  map(module => {
                    //Update version and category data
                    product.head = module.version;
                    product.data ??= [];
                    product.data['categories'] = vModule.document?.categories;
                    return module;
                  })
                );
            } else {
              return of(null);
            }
          })
        )
      );

      // Wait for all update operations to complete
      forkJoin(renameModules).subscribe({
        next: () => {
          this.alertService.success(`Category successfully renamed`);
          this.getCategories(true);
          this.isRenamingCategory = false;
          this.renamingCategoryStr = '';
        },
      });
    } else if (event.key === 'Escape') {
      //Cancel renaming
      this.isRenamingCategory = false;
      this.renamingCategoryStr = '';
    }
  }

  /**
   * On extra menu option click
   */
  extraOptionClick(option: 'edit' | 'delete'): void {
    switch (option) {
      case 'delete':
        this.deleteCategory(this.extraOptionsCategory, this.products);
        break;
      default:
        this.isRenamingCategory = true;
        this.renamingCategoryStr = this.extraOptionsCategory;
        timer(0).subscribe(() => {
          //Input is going to be true on next render, so focus on it
          this.searchCategoryInput.first.nativeElement.focus();
        });
        break;
    }
  }

  /**
   * Shows extra options menu
   * @param event - the button event
   * @param category - the category that got clicked
   */
  showExtraOptions(event: Event, category: string): void {
    this.extraOptionsCategory = category;
    this.extraOptionsMenu.toggle(event);
    event.stopPropagation();
  }

  /**
   * Resets input and suggestions of categories
   */
  resetCategoriesSuggestions(): void {
    this.categoryInput = '';

    //Get only categories that selectedProducts have in common
    this.categoriesFiltered.common = this.selectedProducts
      .map(product => product.data?.['categories'] ?? [])
      .reduce((acc, categories) =>
        acc.filter((category: string) => categories.includes(category))
      );

    //Get categories that arent in common
    this.categoriesFiltered.suggestions = this.categories.filter(
      category => !this.categoriesFiltered.common.includes(category)
    );

    this.categoriesSuggestions = this.categoriesFiltered.suggestions;
  }

  /**
   * On overlay hide, close renaming
   */
  onOverlayHide(): void {
    this.isRenamingCategory = false;
    this.renamingCategoryStr = '';
    this.categoryClose.emit();
  }
}
