<div
  class="card flex flex-column"
  *ngIf="products">
  <div
    class="pb-4"
    *ngIf="isOverview">
    <div class="flex flex-1 align-items-center justify-content-between mb-3">
      <p class="label-normal font-bold">
        PRODUCTS LIST:
        <span class="label-normal font-regular">
          {{ totalCount }} products</span
        >
      </p>
      <p-button
        *ngIf="isOverview"
        icon="pi pi-arrow-right"
        iconPos="right"
        label="See all"
        [routerLink]="['/products']"
        class="primary"
        styleClass="p-button-text p-button-sm"></p-button>
    </div>
    <p-divider></p-divider>
  </div>

  <ng-container>
    <p-table
      #dt1
      class="mb-5 flex-1"
      [value]="filteredProducts"
      [rows]="rowsNumber"
      [showCurrentPageReport]="true"
      [paginator]="!isOverview"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[10, 25, 50]"
      [scrollable]="false"
      selectionMode="multiple"
      [(selection)]="selectedProducts"
      (selectionChange)="onSelectionChange()"
      [selectionPageOnly]="true">
      <ng-template
        pTemplate="caption"
        *ngIf="includeSearch">
        <inf-toolbar-batch-editor
          [products]="products"
          [showBatchAction]="showBatchAction"
          [selectedProducts]="selectedProducts"
          (searchChange)="filterData($event)"
          (editProducts)="edit()"
          (deleteProducts)="deleteProduct()">
        </inf-toolbar-batch-editor>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th
            style="width: 4rem"
            *ngIf="!isOverview">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th [pSortableColumn]="isOverview ? undefined : 'data.name'">
            Name<span *ngIf="!isOverview">
              <p-sortIcon field="data.name"
            /></span>
          </th>
          <!-- <th>Description</th> -->
          <th>Categories</th>
          <th>Owner</th>
          <th [pSortableColumn]="isOverview ? undefined : 'lastEdited'">
            Last Edited<span *ngIf="!isOverview">
              <p-sortIcon field="lastEdited"
            /></span>
          </th>
          <th>Source</th>
          <th>Parameters</th>
          <th>Connectors</th>
          <!-- <th>Status</th> -->
          <th></th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-value
        let-rowIndex="rowIndex">
        <tr [pContextMenuRow]="value">
          <td *ngIf="!isOverview">
            <p-tableCheckbox [value]="value"></p-tableCheckbox>
          </td>
          <td>{{ value.data.name | truncate : [30, '...'] }}</td>
          <!-- <td>{{ value.data.description | truncate :[30,'...']  }}</td> -->
          <td>
            <ng-container *ngIf="value.data.categories">
              <!-- <p-chip *ngFor="let category of value.data.categories" [label]="category"></p-chip> -->
              <p
                *ngFor="
                  let category of value.data.categories;
                  trackBy: categoryTrackBy
                ">
                {{ category }}
              </p>
            </ng-container>
          </td>
          <td>
            <inf-avatar
              [user]="value.owner"
              [size]="'small'"
              [pTooltip]="getOwnerName(value.owner)"
              tooltipPosition="top"></inf-avatar>
          </td>
          <td>
            <inf-progress
              [type]="'tech.cae.infinitive.model.products.Module'"
              [objectID]="value.id">
              <p class="body-medium">
                {{ value.lastEdited | date : 'dd MMMM yyyy' }}
              </p>
            </inf-progress>
          </td>
          <td>
            {{ value.data.source }}
          </td>
          <td>
            {{ value.data.nParameters || 0 }}
          </td>
          <td>
            {{ value.data.nConnectors || 0 }}
          </td>
          <td>
            <div class="flex gap-2 justify-content-end">
              <!-- EDIT -->
              <p-button
                *ngIf="!isOverview"
                pTooltip="Edit"
                tooltipPosition="top"
                icon="pi pi-pencil"
                styleClass="p-button-text p-button-rounded"
                (click)="edit(value)"></p-button>

              <!-- DUPLICATE -->
              <p-button
                *ngIf="!isOverview"
                pTooltip="Duplicate"
                tooltipPosition="top"
                icon="pi pi-clone"
                styleClass="p-button-text p-button-rounded"
                (click)="duplicateProduct(value)"></p-button>

              <!-- DELETE -->
              <p-button
                *ngIf="!isOverview"
                pTooltip="Delete"
                tooltipPosition="top"
                icon="pi pi-trash"
                styleClass="p-button-text p-button-rounded"
                (click)="deleteProduct(value)"></p-button>

              <p-button
                *ngIf="isOverview"
                styleClass="p-button-text p-button-rounded"
                icon="pi pi-pencil"
                (click)="edit(value)"></p-button>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">No products found.</td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>

  <!-- <div
    *ngIf="modules && !modules.length"
    class="align-items-center flex flex-column">
    <inf-empty-state
      class="mb-5"
      subtitle="No products found"
      title="Your products will be listed here"></inf-empty-state>
    <button
      class="p-button-sm align-self-end"
      icon="pi pi-plus"
      iconPos="left"
      label="Add product"
      pButton></button>
  </div> -->
</div>
