import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { NotificationButtonComponent } from './notification-button/notification-button.component';

@Component({
  selector: 'inf-header',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    UserMenuComponent,
    NotificationButtonComponent,
  ],
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  @Input() includeNotifications: boolean = false;
  menuOpen = false;

  notificationOpened(): void {
    this.menuOpen = false;
  }
}
