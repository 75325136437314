/**
 * Overwrites color picker from primeng v15.4.1 to make it ignore for scroll listener
 * https://github.com/primefaces/primeng/blob/15.4.1/src/app/components/colorpicker/colorpicker.ts
 */

import {
  NgModule,
  Component,
  forwardRef,
  ChangeDetectionStrategy,
  ViewEncapsulation,
} from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ColorPicker, ColorPickerModule } from 'primeng/colorpicker';

export const COLORPICKER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InfColorPicker),
  multi: true,
};

@Component({
  selector: 'inf-color-picker',
  template: `
    <div
      #container
      [ngStyle]="style"
      [class]="styleClass"
      [ngClass]="{
        'p-colorpicker p-component': true,
        'p-colorpicker-overlay': !inline,
        'p-colorpicker-dragging': colorDragging || hueDragging,
      }">
      <input
        #input
        type="text"
        *ngIf="!inline"
        class="p-colorpicker-preview p-inputtext"
        readonly="readonly"
        [ngClass]="{ 'p-disabled': disabled }"
        (focus)="onInputFocus()"
        (click)="onInputClick()"
        (keydown)="onInputKeydown($event)"
        [attr.id]="inputId"
        [attr.tabindex]="tabindex"
        [disabled]="disabled"
        [style.backgroundColor]="inputBgColor" />
      <div
        *ngIf="inline || overlayVisible"
        [ngClass]="{
          'p-colorpicker-panel': true,
          'p-colorpicker-overlay-panel': !inline,
          'p-disabled': disabled,
        }"
        (click)="onOverlayClick($event)"
        [@overlayAnimation]="{
          value: 'visible',
          params: {
            showTransitionParams: showTransitionOptions,
            hideTransitionParams: hideTransitionOptions,
          },
        }"
        [@.disabled]="inline === true"
        (@overlayAnimation.start)="onOverlayAnimationStart($event)"
        (@overlayAnimation.done)="onOverlayAnimationEnd($event)">
        <div class="p-colorpicker-content">
          <div
            #colorSelector
            class="p-colorpicker-color-selector"
            (touchstart)="onColorTouchStart($event)"
            (touchmove)="onMove($event)"
            (touchend)="onDragEnd()"
            (mousedown)="onColorMousedown($event)">
            <div class="p-colorpicker-color">
              <div
                #colorHandle
                class="p-colorpicker-color-handle"></div>
            </div>
          </div>
          <div
            #hue
            class="p-colorpicker-hue"
            (mousedown)="onHueMousedown($event)"
            (touchstart)="onHueTouchStart($event)"
            (touchmove)="onMove($event)"
            (touchend)="onDragEnd()">
            <div
              #hueHandle
              class="p-colorpicker-hue-handle"></div>
          </div>
        </div>
      </div>
    </div>
  `,
  animations: [
    trigger('overlayAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scaleY(0.8)' }),
        animate('{{showTransitionParams}}'),
      ]),
      transition(':leave', [
        animate('{{hideTransitionParams}}', style({ opacity: 0 })),
      ]),
    ]),
  ],
  providers: [COLORPICKER_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'p-element',
  },
  styles: [
    `
      .p-colorpicker {
        display: inline-block;
      }
      .p-colorpicker-dragging {
        cursor: pointer;
      }
      .p-colorpicker-overlay {
        position: relative;
      }
      .p-colorpicker-panel {
        position: relative;
        width: 193px;
        height: 166px;
      }
      .p-colorpicker-overlay-panel {
        position: absolute;
        top: 0;
        left: 0;
      }
      .p-colorpicker-preview {
        cursor: pointer;
      }
      .p-colorpicker-panel .p-colorpicker-content {
        position: relative;
      }
      .p-colorpicker-panel .p-colorpicker-color-selector {
        width: 150px;
        height: 150px;
        top: 8px;
        left: 8px;
        position: absolute;
      }
      .p-colorpicker-panel .p-colorpicker-color {
        width: 150px;
        height: 150px;
      }
      .p-colorpicker-panel .p-colorpicker-color-handle {
        position: absolute;
        top: 0;
        left: 150px;
        border-radius: 100%;
        width: 10px;
        height: 10px;
        border-width: 1px;
        border-style: solid;
        margin: -5px 0 0 -5px;
        cursor: pointer;
        opacity: 0.85;
      }
      .p-colorpicker-panel .p-colorpicker-hue {
        width: 17px;
        height: 150px;
        top: 8px;
        left: 167px;
        position: absolute;
        opacity: 0.85;
      }
      .p-colorpicker-panel .p-colorpicker-hue-handle {
        position: absolute;
        top: 150px;
        left: 0;
        width: 21px;
        margin-left: -2px;
        margin-top: -5px;
        height: 10px;
        border-width: 2px;
        border-style: solid;
        opacity: 0.85;
        cursor: pointer;
      }
    `,
  ],
})
export class InfColorPicker extends ColorPicker {
  bindScrollListener(): void {}
}

@NgModule({
  imports: [CommonModule, ColorPickerModule],
  exports: [InfColorPicker],
  declarations: [InfColorPicker],
})
export class InfColorPickerModule {}
