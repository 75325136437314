<div class="integration-box p-3">
  <div class="border-round-md integration-image mb-2 p-4">
    <img
      [alt]="name"
      [ngSrc]="src"
      width="48"
      height="48"
      priority />
  </div>
  <div class="align-items-center flex flex-column">
    <div>{{ name }}</div>
    <div
      *ngIf="isConnected"
      class="text-secondary text-xs">
      Connected
    </div>
    <button
      *ngIf="!isConnected"
      class="mt-2"
      label="Connect"
      pButton></button>
  </div>
</div>
